import * as Sentry from "@sentry/react";
import { ArrowUp } from "lucide-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { countriesEntries, countryToISO3166 } from "shared/model/countries";

import {
  SearchableSelect,
  SelectOption,
} from "../../../../../input/searchable-select";
import { ChatOptionComponentProps } from "../lookup";

export const CountryDropdown: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const { t } = useTranslation();

  const countryOptions = countriesEntries.map(([key, value]) => ({
    key,
    value: t(`countries.${value}`),
  }));

  const [selectedCountry, setSelectedCountry] = useState<
    SelectOption | undefined
  >();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isNeedSelection, setIsNeedSelection] = useState(false);

  const handleSubmit = () => {
    if (!selectedCountry) {
      setIsNeedSelection(true);
      return { hasResponseBeenSent: false };
    }

    const iso3166 = countryToISO3166(selectedCountry.key);
    if (!iso3166.found) {
      Sentry.captureException(
        new Error(`Invalid selection for country: ${selectedCountry.key}`),
        {
          level: "error",
        },
      );
      setSelectedCountry(undefined);
      setIsNeedSelection(true);
      return { hasResponseBeenSent: false };
    }

    sendResponse({
      message: selectedCountry.value,
      selection: [iso3166.value],
    });
    setIsSubmitted(true);
    setSelectedCountry(undefined);

    return { hasResponseBeenSent: true };
  };

  return (
    <div className="flex w-full items-center justify-between gap-2 p-2">
      <SearchableSelect
        options={countryOptions}
        placeholder={t("graph.choice.COUNTRY.placeholder")}
        searchPlaceholder={t("graph.text.search")}
        title="country"
        disabled={isSubmitted}
        onSelect={(option) => {
          setSelectedCountry(option);
          setIsNeedSelection(false);
        }}
        showError={isNeedSelection}
        errorMessage={t("graph.text.errorMessage.selectOne")}
        top
      />

      <button
        className="btn-primary btn-square btn"
        onClick={handleSubmit}
        disabled={isSubmitted}
      >
        <ArrowUp width={18} />
      </button>
    </div>
  );
};
