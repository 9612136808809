import { ArrowUp } from "lucide-react";
import { useState } from "react";

import { Choices } from "./fragments/choices";
import { useT } from "../../../../../../i18n/use-t";
import { ChatOptionComponentProps } from "../lookup";
import {
  SearchableSelect,
  SelectOption,
} from "~/components/input/searchable-select";

export const SingleChoice: React.FC<ChatOptionComponentProps> = ({
  choices,
  ...rest
}) => {
  const t = useT();
  const [selected, setSelected] = useState<SelectOption | undefined>(undefined);

  const options = choices.map((choice) => ({
    key: choice,
    value: t({ tx: choice }),
  }));

  return choices.length <= 3 ? (
    <Choices choices={choices} {...rest} />
  ) : (
    <div className="flex w-full items-center justify-center gap-3">
      <SearchableSelect
        options={options}
        onSelect={setSelected}
        placeholder="Select one"
        title="auz"
        top
      />
      <button
        type="button"
        className="btn-primary btn-square btn"
        disabled={!selected}
        onClick={() => {
          if (!selected) {
            return;
          }

          rest.sendResponse({
            message: selected.value,
            selection: [selected.key],
          });
        }}
      >
        <ArrowUp width={18} />
      </button>
    </div>
  );
};
