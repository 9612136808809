import { User } from "lucide-react";
import { useRef, useState } from "react";

import { useToasts } from "~/components/app-shell/providers/toasts-provider/toasts-provider";
import {
  BottomDrawerRef,
  BottomDrawerResponsive,
} from "~/components/bottom-drawer/responsive";
import { Text } from "~/components/text";
import { Profile } from "~/profile/profile";

export const ButtonProfile: React.FC = () => {
  const [hasChanges, setHasChanges] = useState(false);
  const drawerRef = useRef<BottomDrawerRef>(null);

  const toasts = useToasts();
  return (
    <BottomDrawerResponsive
      button={({ open }) => (
        <button
          type="button"
          onClick={open}
          className="btn-ghost btn-block btn justify-start"
        >
          <User width={16} />
          <Text tx="profile.headerTitle" />
        </button>
      )}
      mayClose={() => {
        if (hasChanges) {
          drawerRef.current?.open();
          return false;
        }

        return true;
      }}
    >
      {({ close, forceClose }) => (
        <>
          <Profile
            onSavedProfile={() => {
              toasts.addToast({
                component: <Text tx="profile.updateSuccessful" />,
              });
              close();
            }}
            setHasChanges={setHasChanges}
          />
          <BottomDrawerResponsive ref={drawerRef}>
            {({ close: closeWarning }) => (
              <ProfileUpdate
                onNo={closeWarning}
                onYes={() => {
                  closeWarning();
                  forceClose();
                }}
              />
            )}
          </BottomDrawerResponsive>
        </>
      )}
    </BottomDrawerResponsive>
  );
};

const ProfileUpdate: React.FC<{ onNo: () => void; onYes: () => void }> = ({
  onNo,
  onYes,
}) => {
  return (
    <div className="text-mama-default-primary">
      <Text
        as="h3"
        className="mb-3 text-lg font-semibold"
        tx="profile.unsavedChangesTitle"
      />

      <Text as="p" tx="profile.unsavedChanges" className="mb-4" />

      <div className="flex items-center justify-end gap-2">
        <button type="button" className="btn-ghost btn" onClick={onNo}>
          <Text tx="general.no" />
        </button>
        <button type="button" className="btn-primary btn" onClick={onYes}>
          <Text tx="general.yes" />
        </button>
      </div>
    </div>
  );
};
