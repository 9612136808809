import { ArrowUp } from "lucide-react";
import { useState } from "react";

import { ChatOptionComponentProps } from "../lookup";
import { SelfAdjustingTextarea } from "~/components/input/self-adjusting-textarea";
import { Text } from "~/components/text";

export const TextDrawerSimple: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const [message, setMessage] = useState("");

  const submit = () => {
    const { hasResponseBeenSent } = sendResponse({ message, selection: [] });
    if (hasResponseBeenSent) {
      setMessage("");
    }
  };

  return (
    <div className="mx-auto mb-4 flex w-full max-w-screen-sm flex-col gap-1.5 px-2">
      <div className="flex flex-nowrap items-start gap-2 rounded-xl border border-neutral/10 bg-base-200 p-2.5 pl-4 shadow-xl">
        <SelfAdjustingTextarea
          text={message}
          setText={setMessage}
          className="max-h-40 w-full resize-none appearance-none break-words bg-transparent leading-relaxed text-mama-default-primary caret-mama-default-primary outline-none"
          onEnterPressed={submit}
          autoFocus
        />
        <button
          className="btn-primary btn-square btn-sm btn shadow-md"
          onClick={submit}
          disabled={!message.length}
        >
          <ArrowUp width={18} height={18} />
        </button>
      </div>
      <p className="flex flex-wrap justify-center gap-2 text-xs text-mama-default-primary/80">
        <Text as="span" tx="legal.canMakeMistakes" />
        <Text as="span" tx="legal.pleaseDoubleCheck" />
      </p>
    </div>
  );
};
