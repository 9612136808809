import React from "react";

import { EmbellishmentVisibilityProvider } from "../../../../../app-shell/providers/embellishment-visibility-provider";
import {
  LanguageSelectorVisibilityProvider,
  useLanguageSelectorVisibility,
} from "../../../../../app-shell/providers/language-selector-visibility-provider";
import { AuthPagesEnum } from "~/auth/constants";
import { Header } from "~/components/app-shell/header/base";
import { LanguageSelector } from "~/components/app-shell/language-selector";
import { LogoWide } from "~/components/app-shell/logo";
import { Text } from "~/components/text";
import { useT } from "~/i18n/use-t";
import { cn } from "~/util/cn";
import { useMetadataForActiveRoute } from "~/util/metadata-for-active-route";

export const EcommerceLayout: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  const t = useT();

  useMetadataForActiveRoute([
    {
      path: AuthPagesEnum.ONBOARDING,
      position: "start",
      title: t({ tx: "graph.text.metadata.title" }),
      isActive: true,
    },
  ]);

  return (
    <LanguageSelectorVisibilityProvider>
      <EmbellishmentVisibilityProvider>
        {(Component) => (
          <Main Component={Component} className={className}>
            {children}
          </Main>
        )}
      </EmbellishmentVisibilityProvider>
    </LanguageSelectorVisibilityProvider>
  );
};

const Main: React.FC<
  React.PropsWithChildren<{
    Component: JSX.Element | null;
    className?: string;
  }>
> = ({ Component, className, children }) => {
  const { isVisible } = useLanguageSelectorVisibility();

  return (
    <div
      style={{ height: "100dvh" }}
      className="grid h-screen grid-cols-1 grid-rows-[4.5rem_auto_min-content] bg-gradient-to-bl from-mama-green-300/5 to-transparent"
    >
      {Component}
      <Header
        className="z-50 border-0 bg-transparent"
        middle={<LogoWide />}
        right={isVisible ? <LanguageSelector /> : null}
      />
      <main
        className={cn(
          "isolate z-10 flex flex-col items-center px-4 pb-4 pt-10",
          className,
        )}
      >
        {children}
      </main>
      <Footer className="z-0 mx-auto max-w-2xl" />
    </div>
  );
};

const Footer: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <footer
      className={cn(
        "w-full px-6 py-8 text-center text-sm text-mama-default-primary",
        className,
      )}
    >
      <ul className="flex flex-wrap items-center justify-between gap-x-2">
        <li className="flex-1">
          <Text
            tx="signUp.customFields.mamaTermsAndConditions.href"
            txComponents={{
              href: <a className="link-hover link" target="_blank" />,
            }}
          />
        </li>

        <li className="flex-1">
          <Text
            tx="signUp.customFields.mamaPrivacyPolicy.href"
            txComponents={{
              href: <a className="link-hover link" target="_blank" />,
            }}
          />
        </li>
      </ul>
    </footer>
  );
};
