import { PanelLeftOpen } from "lucide-react";
import { PropsWithChildren, useRef } from "react";
import { EnabledPage } from "shared/model/pages";

import { ButtonProfile } from "./button-profile";
import { ButtonSignOut } from "./button-signout";
import { ButtonTutorial } from "./button-tutorial";
import { NavLinks, useSplitNavbarRoutes } from "./nav-links";
import { InjectTypeForm } from "../../components/inject-typeform";
import { LockGuard } from "../../components/lock";
import { UserFeedbackModal } from "../../components/user-feedback/user-feedback-modal";
import { useHeaderRoutes } from "../use-header-routes";
import { Header } from "~/components/app-shell/header/base";
import { LogoWide } from "~/components/app-shell/logo";
import { usePageChange } from "~/util/hooks";
import { useMetadataForActiveRoute } from "~/util/metadata-for-active-route";

const SideDrawer: React.FC<{ drawerId: string }> = ({ drawerId }) => {
  const headerRoutes = useHeaderRoutes();
  useMetadataForActiveRoute(headerRoutes);
  const { start, end } = useSplitNavbarRoutes(headerRoutes);

  return (
    <div className="drawer-side">
      <label
        htmlFor={drawerId}
        aria-label="close sidebar"
        className="drawer-overlay"
      />
      <ul className="max-w-72 flex min-h-full w-[80vw] flex-col justify-between border-r border-neutral/10 bg-base-200 p-4">
        <li className="pointer-events-none mb-8">
          <LogoWide className="h-10 w-full" />
        </li>

        <li>
          <ul className="flex flex-col gap-2">
            <NavLinks routes={start} />
          </ul>
        </li>

        <li className="mt-auto">
          <ul>{end.length >= 1 && <NavLinks routes={end} />}</ul>
        </li>

        <li>
          <ul className="flex flex-col gap-2">
            <li>
              <ButtonProfile />
            </li>
            <li>
              <ButtonTutorial />
            </li>
            <li>
              <ButtonSignOut />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export const TenantPage: React.FC<
  PropsWithChildren<{ page?: EnabledPage }>
> = ({ children, page }) => {
  const drawerId = "side-drawer" as const;
  const drawerRef = useRef<HTMLInputElement>(null);

  usePageChange(() => {
    if (drawerRef.current?.checked) {
      drawerRef.current?.click();
    }
  });

  return (
    <div className="drawer lg:drawer-open">
      <input
        ref={drawerRef}
        id={drawerId}
        type="checkbox"
        className="drawer-toggle"
      />
      <div className="h-dvh drawer-content grid grid-cols-1 grid-rows-[4.5rem_calc(100%-4.5rem)] lg:grid-rows-1">
        <div className="lg:hidden">
          <Header
            breakpoint="sm"
            middle={<LogoWide />}
            left={
              <label
                htmlFor={drawerId}
                aria-label="open sidebar"
                className="btn-ghost btn-square btn"
              >
                <PanelLeftOpen />
              </label>
            }
          />
        </div>

        <div>
          <UserFeedbackModal />
          <InjectTypeForm page={page}>
            <LockGuard page={page}>{children}</LockGuard>
          </InjectTypeForm>
        </div>
      </div>
      <SideDrawer drawerId={drawerId} />
    </div>
  );
};
