const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as const;

export const resolveBreakpoint = (bp: number | string): number => {
  if (typeof bp === "number") {
    return bp;
  }

  return breakpoints[bp as keyof typeof breakpoints];
};
