import React, { PropsWithChildren } from "react";

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className="h-dvh w-full overflow-y-auto overflow-x-hidden"
      data-theme="light"
    >
      {children}
    </div>
  );
};
