import {
  RefObject,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
  useSyncExternalStore,
} from "react";
import { useLocation } from "react-router-dom";
import { DeepWriteable } from "shared/utils/utility";

export const useDestructable = <
  Config extends readonly unknown[],
  Destructable,
>(
  create: (...config: DeepWriteable<Config>) => Destructable,
  destroy: (destructable: Destructable) => void,
  config: Config,
): Destructable | undefined => {
  const [destructable, setDestructable] = useState<undefined | Destructable>(
    undefined,
  );
  useEffect(() => {
    const nextDestructable = create(...config);
    setDestructable(() => nextDestructable);
    return () => destroy(nextDestructable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, config);
  return destructable;
};

export const useIntersectionObserver = (
  ref: React.RefObject<HTMLElement>,
  options: IntersectionObserverInit = {},
): boolean => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, options]);

  return isIntersecting;
};

export const useDetailsBlur = (
  containerRef: RefObject<HTMLDetailsElement>,
  isDisabled?: boolean,
): void => {
  useEffect(() => {
    if (!isDisabled) {
      const closeModal = (e: WindowEventMap["click" | "blur"]) => {
        if (
          containerRef.current &&
          (!(e.target instanceof Node) ||
            !containerRef.current.contains(e.target))
        ) {
          containerRef.current.open = false;
        }
      };
      window.addEventListener("click", closeModal);
      window.addEventListener("blur", closeModal);
      return () => {
        window.removeEventListener("click", closeModal);
        window.removeEventListener("blur", closeModal);
      };
    }
  }, [containerRef, isDisabled]);
};

const subscribeToResizeEvent = (callback: (ev: UIEvent) => void) => {
  window.addEventListener("resize", callback);
  return () => {
    window.removeEventListener("resize", callback);
  };
};

export const useDimensions = (
  ref: RefObject<HTMLElement>,
): { width: number; height: number } => {
  // https://stackoverflow.com/a/75101934
  const dimensions = useSyncExternalStore(subscribeToResizeEvent, () =>
    JSON.stringify({
      width: ref.current?.offsetWidth ?? 0,
      height: ref.current?.offsetHeight ?? 0,
    }),
  );
  return useMemo(() => JSON.parse(dimensions), [dimensions]);
};

export const useWindowDimensions = (): { width: number; height: number } => {
  const dimensions = useSyncExternalStore(subscribeToResizeEvent, () =>
    JSON.stringify({
      width: window.innerWidth,
      height: window.innerHeight,
    }),
  );
  return useMemo(() => JSON.parse(dimensions), [dimensions]);
};

export const useIsMountedRef = (): { current: boolean } => {
  const mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted;
};

export const useForceUpdateAfter = ({
  milliseconds,
}: {
  milliseconds: number;
}): void => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    setTimeout(() => forceUpdate(), milliseconds);
  }, [milliseconds]);
};

export const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay]);

  return debouncedValue;
};

/**
 *
 * @param effect
 * Running fetch requests on rate limited apis in a useEffect causes them to be run twice when
 * strict mode is on. This is not a problem in a production build but during local development
 * causes bugs. This hook is a workaround.
 */
export const useStrictModeSafeEffect = (effect: () => Promise<void>): void => {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      effect();
    }
  }, [effect]);
};

export const useToggle = (
  initialValue = false,
): [value: boolean, toggle: () => void] => {
  const [value, setValue] = useState(initialValue);

  const toggle = () => {
    setValue((prevValue) => !prevValue);
  };

  return [value, toggle];
};

export const useWatchChange = <T>(
  value: T,
  callback: (newValue: T, oldValue: T) => void,
): void => {
  const previousValueRef = useRef<T>(value);

  useEffect(() => {
    if (previousValueRef.current !== value) {
      callback(value, previousValueRef.current);
      previousValueRef.current = value;
    }
  }, [value, callback]);
};

export const usePageChange = (callback: (pathname: string) => void): void => {
  const location = useLocation();

  useEffect(() => {
    if (callback && typeof callback === "function") {
      callback(location.pathname);
    }
  }, [location, callback]);
};
