import { useMemo } from "react";
import { PlainI18nProps } from "shared/types/i18n";

import { Text } from "./text";
import { i18nPropsHaveContent } from "../i18n/i18n-props-have-content";
import { cn } from "~/util/cn";

export type LoadingIndicatorProps = {
  as?: "spinner" | "dots" | "ring";
  indicatorClassName?: string;
  message?: PlainI18nProps;
} & JSX.IntrinsicElements["div"];

const loadingClasses = {
  spinner: "loading loading-spinner loading-md",
  dots: "loading loading-dots loading-md",
  ring: "loading loading-ring loading-md",
};

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  as = "spinner",
  className,
  message,
  indicatorClassName,
  ...rest
}) => {
  const hasMessage = useMemo(() => i18nPropsHaveContent(message), [message]);
  const loadingClass = loadingClasses[as] || loadingClasses.spinner; // Fallback to spinner

  return (
    <div
      role="status"
      className={cn("flex items-center justify-end", className)}
      {...rest}
    >
      <span className={cn(loadingClass, indicatorClassName, "mx-2")} />
      {hasMessage && <Text className="text-sm font-light" {...message} />}
    </div>
  );
};
