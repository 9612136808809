import { HTMLAttributes, useEffect, useRef } from "react";

import { useT } from "~/i18n/use-t";

export const SelfAdjustingTextarea: React.FC<{
  text: string;
  setText: (text: string) => void;
  onEnterPressed?: (text: string) => void;
  className: string;
  placeholder?: string;
  autoFocus?: boolean;
  inputMode?: HTMLAttributes<HTMLTextAreaElement>["inputMode"];
  canUpdateText?: (newText: string) => boolean;
}> = ({
  text,
  setText,
  onEnterPressed,
  className,
  placeholder,
  autoFocus,
  inputMode,
  canUpdateText = () => true,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const t = useT();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [text]);

  return (
    <textarea
      ref={textareaRef}
      value={text}
      onChange={(event) => {
        const { value } = event.target;

        if (canUpdateText(value)) {
          setText(value);
        }
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter" && !event.shiftKey) {
          if (text.length == 0) {
            event.preventDefault();
            return;
          }

          if (onEnterPressed) {
            event.preventDefault();
            onEnterPressed(text);
          }
        }
      }}
      className={className}
      placeholder={
        placeholder ?? t({ tx: "general.typeSomethingInputPlaceholder" })
      }
      autoFocus={autoFocus}
      inputMode={inputMode}
    />
  );
};
