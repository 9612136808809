import { LogOut } from "lucide-react";

import { useAuthContext } from "~/auth/auth-store";
import { Text } from "~/components/text";

export const ButtonSignOut: React.FC = () => {
  const { signOut } = useAuthContext();

  if (!signOut) {
    return null;
  }

  return (
    <button
      type="button"
      className="btn-outline btn-block btn justify-start border border-neutral/10"
      onClick={signOut}
    >
      <LogOut width={16} />
      <Text tx="auth.signOut.signOutButton" />
    </button>
  );
};
