import { cn } from "~/util/cn";

export const LogoWide: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <img
      className={cn(
        className ||
          "h-7 self-center justify-self-center object-contain object-bottom sm:h-8",
      )}
      src="/resources/mama-logo-wide.svg"
      alt="mama health"
    />
  );
};
