import { createContext, useContext, useEffect, useRef, useState } from "react";

import { Toast, ToastProps, ToastPropsProvidable } from "./toasts";

type ToastsContextProps = {
  addToast: (toast: ToastPropsProvidable) => string;
  removeToast: (toastId: ToastProps["id"]) => void;
};

const ToastsContext = createContext<ToastsContextProps | undefined>({
  addToast: () => "",
  removeToast: () => void 0,
});

export const ToastsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const previousHeight = useRef(0);

  const removeToast = (id: ToastProps["id"]) => {
    setToasts((prev) => prev.filter((prevToast) => prevToast.id !== id));
  };

  const addToast: ToastsContextProps["addToast"] = (toast) => {
    previousHeight.current = containerRef.current?.offsetHeight || 0;

    const id = new Date().getTime().toString(36);
    const newToast: ToastProps = {
      ...toast,
      id,
      onRemove: () => removeToast(id),
    };
    setToasts((prev) => [...prev, newToast]);
    return id;
  };

  useEffect(() => {
    if (containerRef.current && previousHeight.current > 0) {
      const newHeight = containerRef.current.offsetHeight;

      const invertDistance = previousHeight.current - newHeight;

      const animation = containerRef.current.animate(
        [
          { transform: `translateY(${-invertDistance}px)` },
          { transform: "translateY(0)" },
        ],
        {
          duration: 150,
          easing: "ease-out",
        },
      );

      animation.onfinish = () => {
        previousHeight.current = 0;
      };
    }
  }, [toasts]);

  return (
    <ToastsContext.Provider value={{ addToast, removeToast }}>
      {children}
      <div
        ref={containerRef}
        className="pointer-events-none fixed inset-x-0 bottom-0 mx-4 grid justify-stretch justify-items-center gap-2 pb-4 sm:pb-4"
      >
        {toasts.map((toast) => (
          <Toast key={toast.id} {...toast} />
        ))}
      </div>
    </ToastsContext.Provider>
  );
};

export const useToasts = (): ToastsContextProps => {
  const context = useContext(ToastsContext);
  if (!context) {
    throw new Error("useToasts must be used within a ToastsProvider");
  }
  return context;
};
