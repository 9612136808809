import React, { useState } from "react";

import { ChatOptionComponentProps } from "../lookup";
import { SelfAdjustingTextareaWithAttachments } from "~/components/input/self-adjusting-textarea-with-attachments";

export const TextDrawer: React.FC<ChatOptionComponentProps> = ({
  sendResponse: onSendMessage,
}) => {
  const [message, setMessage] = useState("");

  return (
    <div className="mx-auto mb-4 flex w-full max-w-screen-sm flex-col gap-1.5 px-2">
      <SelfAdjustingTextareaWithAttachments
        text={message}
        setText={setMessage}
        onSubmit={({ payload, reset }) => {
          const { hasResponseBeenSent } = onSendMessage({
            message: payload.text,
            base64Images: payload.attachments.map(
              (attachment) => attachment.base64,
            ),
            base64Audio: payload.base64Audio,
            selection: [],
          });
          if (hasResponseBeenSent) {
            reset();
          }
        }}
      />
    </div>
  );
};
