import { SIGNUP_MAXIMUM_AGE, SIGNUP_MINIMUM_AGE } from "shared/utils/constants";

import { FormTextInput } from "../../components/form/form-text-input";
import { useT } from "../../i18n/use-t";
import { isOlderThan, isYoungerThan } from "../../util/age-comparison";
import { DataCollectionImplementation } from "../types";

export const dateOfBirthInput: DataCollectionImplementation = {
  wasCompleted: (user) => !!user.userAttributes?.dateOfBirth,
  Component: ({ errors, register }) => {
    const t = useT();

    return (
      <FormTextInput
        type="number"
        labelClassName="font-bold"
        title={{ tx: "userData.dateOfBirth" }}
        error={{ txUnchecked: errors.dateOfBirth?.message }}
        {...register("dateOfBirth", {
          validate: (value) => {
            if (!value) {
              return t({ tx: "userData.dateOfBirthIsRequiredError" });
            }

            if (
              !isOlderThan({
                year: Number(value),
                minimumAge: SIGNUP_MINIMUM_AGE,
              })
            ) {
              return t({
                tx: "userData.youMustBeOlderError",
                txData: { age: SIGNUP_MINIMUM_AGE },
              });
            }

            if (
              !isYoungerThan({
                year: Number(value),
                maximumAge: SIGNUP_MAXIMUM_AGE,
              })
            ) {
              return t({
                tx: "userData.youMustBeYoungerError",
                txData: { age: SIGNUP_MAXIMUM_AGE },
              });
            }
          },
        })}
      />
    );
  },
};
