import { Mail } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { SECONDS_MAGIC_LINK_COOLDOWN } from "shared/utils/constants";

import { userHasCreatedAccount } from "../../../../../util/data-layer-actions";
import { Link } from "../../../../link";
import { Text } from "../../../../text";
import { sniperLinkConfig } from "~/auth/constants";
import { useCredentials } from "~/auth/hooks/use-credentials";
import { BottomDrawerResponsive } from "~/components/bottom-drawer/responsive";
import { useAppLanguage } from "~/components/form/language/use-app-language";
import { SvgIcon } from "~/components/icons/svg-icon";

interface SignUpDrawerProps {
  hasInitialTimeout: boolean;
  reTriggerEmail: () => void;
}

export const SignUpDrawer: React.FC<SignUpDrawerProps> = ({
  hasInitialTimeout,
  reTriggerEmail,
}) => {
  const [countdown, setCountdown] = useState(
    hasInitialTimeout ? SECONDS_MAGIC_LINK_COOLDOWN : 0,
  );
  const { reset: resetCredentials } = useCredentials();

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown((seconds) => seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [countdown]);

  useEffect(userHasCreatedAccount, []);

  return (
    <div className="border-t border-neutral/10 p-2">
      <div className="mx-auto flex max-w-screen-sm flex-col items-center gap-2">
        <Text
          className="text-balance sm:text-nowrap text-center text-sm text-mama-default-primary"
          tx="graph.text.signup.info"
        />
        <Text
          className="text-balance sm:text-nowrap text-center text-sm text-mama-default-primary"
          tx="graph.text.signup.restartChat"
          txComponents={{
            restartChat: (
              <Link
                onClick={async () => {
                  resetCredentials();
                  location.reload();
                }}
              />
            ),
          }}
        />
        <div className="grid w-full grid-cols-[auto_min-content] grid-rows-1 gap-2">
          <button
            disabled={countdown > 0}
            key="graph.choices.SIGNUP.resend"
            onClick={() => {
              reTriggerEmail();
              setCountdown(SECONDS_MAGIC_LINK_COOLDOWN);
            }}
            className="btn-primary btn"
          >
            <Text
              tx={
                countdown === 0
                  ? "graph.choice.SIGNUP.resend"
                  : "graph.choice.SIGNUP.resendIn"
              }
              txData={{ time: countdown }}
            />
          </button>
          <SniperLinks />
        </div>
      </div>
    </div>
  );
};

const SniperLinks: React.FC = () => {
  const { appLanguage } = useAppLanguage();
  const sniperLinks = useMemo(
    () =>
      Object.values(sniperLinkConfig).filter(
        (link) => !link.language || link.language === appLanguage,
      ),
    [appLanguage],
  );

  return (
    <BottomDrawerResponsive
      breakpoint="sm"
      button={({ open }) => (
        <button
          onClick={open}
          className="btn-primary btn-outline btn-square btn"
        >
          <Mail />
        </button>
      )}
    >
      <div>
        <ul className="grid grid-cols-3 gap-3">
          {sniperLinks.map(({ link, logo }) => (
            <li key={link}>
              <Link href={link} className="btn-lg btn w-full">
                <SvgIcon icon={logo} className="w-10" />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </BottomDrawerResponsive>
  );
};
