import { ChevronDown } from "lucide-react";
import { MouseEventHandler, useRef } from "react";

import { GhostButton } from "./button";
import { useDetailsBlur } from "../../util/hooks";
import { I18nProps, Text } from "../text";
import { Tooltip } from "../tooltip";
import { cn } from "~/util/cn";

export type DropdownProps<T> = {
  value?: T;
  noValueSelected: I18nProps;
  onChange?: (value: T) => void | undefined;
  options: {
    value: T;
    label: I18nProps;
    disabled?: { isOptionDisabled: boolean; reasonWhyDisabled?: I18nProps };
  }[];
  className?: string;
  disabled?: boolean;
  overflowVisible?: boolean;
  selectedValueClassName?: string;
  searchResultsOnTop?: boolean;
  summaryRenderer?: (active: {
    language: T;
    i18nProps: I18nProps;
  }) => React.ReactNode;
  summaryItem?: (active: {
    language: T;
    i18nProps: I18nProps;
    onClick: MouseEventHandler<HTMLButtonElement>;
  }) => React.ReactNode;
};

export const Dropdown = <T extends string | number | boolean>({
  value,
  noValueSelected,
  options,
  onChange,
  className,
  disabled,
  overflowVisible,
  selectedValueClassName,
  searchResultsOnTop,
  summaryRenderer,
  summaryItem,
}: DropdownProps<T>): ReturnType<React.FC> => {
  const dropDownRef = useRef<HTMLDetailsElement>(null);
  useDetailsBlur(dropDownRef);

  const activeIndex = options.findIndex((option) => option.value === value);
  const i18nProps =
    activeIndex === -1 ? noValueSelected : options[activeIndex].label;

  return (
    <details
      className={cn(
        "group/dropdown",
        "dropdown-end dropdown",
        disabled ? "pointer-events-none opacity-30" : "",
        className,
      )}
      ref={dropDownRef}
    >
      <summary className="btn-ghost btn-block btn">
        {!i18nProps || !value ? (
          <Text
            className={cn("whitespace-nowrap", selectedValueClassName)}
            {...noValueSelected}
          />
        ) : summaryRenderer ? (
          summaryRenderer({ language: value, i18nProps })
        ) : (
          <Text
            className={cn("whitespace-nowrap", selectedValueClassName)}
            {...i18nProps}
          />
        )}

        <ChevronDown className="transition-transform ease-in-out group-[:open]/dropdown:rotate-180" />
      </summary>

      <ul
        className={cn(
          "absolute z-10 flex max-h-96 w-full flex-col gap-1 rounded-box bg-base-100 p-2 text-left shadow",
          overflowVisible ? "overflow-visible" : "overflow-scroll",
          searchResultsOnTop ? "bottom-12" : "top-12",
        )}
      >
        {options
          .filter((option) => option.value !== value)
          .map((option, index) => (
            <DropdownOption
              key={option.value.toString() + index}
              option={option}
              index={index}
              renderer={summaryItem}
              onClick={(event) => {
                onChange?.(option.value);
                event.preventDefault();
                if (dropDownRef.current?.open) {
                  dropDownRef.current.open = false;
                }
              }}
            />
          ))}
      </ul>
    </details>
  );
};

export type DropdownOptionProps<T> = {
  option: DropdownProps<T>["options"][number];
  index: number;
  renderer: DropdownProps<T>["summaryItem"];
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const DropdownOption = <T extends string | number | boolean>({
  option,
  index,
  renderer,
  onClick,
}: DropdownOptionProps<T>): ReturnType<React.FC> => {
  return (
    <Tooltip
      className="tooltip-left z-20 w-full"
      key={
        typeof option.value === "boolean"
          ? option.value.toString() + index
          : option.value
      }
      text={
        option.disabled?.isOptionDisabled
          ? option.disabled?.reasonWhyDisabled
          : {}
      }
    >
      {renderer ? (
        renderer({ language: option.value, i18nProps: option.label, onClick })
      ) : (
        <GhostButton
          onClick={onClick}
          className="w-full justify-start text-left"
          isDisabled={option.disabled?.isOptionDisabled}
          {...option.label}
        />
      )}
    </Tooltip>
  );
};
