import { Check, ChevronDown } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";

import { cn } from "~/util/cn";

const containsUmlaut = (value: string): boolean => {
  const umlautRegex = /[äöüß]/;
  return umlautRegex.test(value);
};

export type SelectOption = {
  key: string;
  value: string;
};

interface SearchableSelectProps {
  options: SelectOption[];
  placeholder: string;
  searchPlaceholder?: string;
  title: string;
  disabled?: boolean;
  onSelect: (option: SelectOption) => void;
  errorMessage?: string;
  showError?: boolean;
  top?: boolean;
}

export const SearchableSelect: React.FC<SearchableSelectProps> = ({
  options,
  placeholder,
  searchPlaceholder,
  title,
  disabled = false,
  onSelect,
  errorMessage,
  showError = false,
  top = false,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | "default">(
    "default",
  );
  const [selectedLabel, setSelectedLabel] = useState<string>(placeholder);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    buttonRef.current?.focus();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isDropdownOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isDropdownOpen]);

  const handleOptionSelect = (option: SelectOption) => {
    setSelectedValue(option.value);
    setSelectedLabel(option.value);
    setIsDropdownOpen(false);
    setSearchQuery("");
    onSelect(option);
  };

  const toggleDropdown = () => {
    if (disabled) {
      return;
    }

    setIsDropdownOpen(!isDropdownOpen);
    if (!isDropdownOpen) {
      setSearchQuery("");
    }
  };

  const normalizedOptions = useMemo(
    () =>
      options.map(({ key, value }) => ({
        key,
        value,
        normalized: value
          .toLowerCase()
          .replace(/ä/g, "a")
          .replace(/ö/g, "o")
          .replace(/ü/g, "u")
          .replace(/ß/g, "ss"),
      })),
    [options],
  );
  type NormalizedOption = (typeof normalizedOptions)[number];

  const lowerCaseSearchQuery = searchQuery.toLowerCase();

  const filterFunction = containsUmlaut(lowerCaseSearchQuery)
    ? (option: NormalizedOption) =>
        option.value.toLowerCase().includes(lowerCaseSearchQuery)
    : (option: NormalizedOption) =>
        option.normalized.toLowerCase().includes(lowerCaseSearchQuery);

  const filteredOptions = normalizedOptions.filter(filterFunction);

  return (
    <div className="w-full">
      {showError && errorMessage && (
        <p className="font-base my-3 text-sm text-mama-error">{errorMessage}</p>
      )}

      <div
        className={cn("dropdown w-full", top && "dropdown-top")}
        ref={dropdownRef}
      >
        <div>
          <div
            tabIndex={0}
            role="button"
            ref={buttonRef}
            title={title}
            className={cn(
              "btn-ghost input-bordered btn w-full flex-nowrap justify-between hover:border-mama-gray-200 hover:bg-mama-gray-100/20 focus:bg-mama-gray-100/20 focus:outline-primary/80",
              disabled && "btn-disabled pointer-events-none",
            )}
            onClick={toggleDropdown}
          >
            <span className="text-balance text-left">{selectedLabel}</span>

            <ChevronDown
              className={cn(
                "flex-shrink-0 transition-transform delay-75 duration-300 ease-in-out",
                isDropdownOpen ? "rotate-180" : "rotate-0",
              )}
            />
          </div>
        </div>
        <div
          tabIndex={0}
          className={cn(
            "dropdown-content z-10 my-1 w-full rounded-box border border-base-content/20 bg-base-100 p-2 shadow",
            !isDropdownOpen && "hidden",
          )}
        >
          <div className="p-2">
            <input
              ref={searchInputRef}
              type="text"
              placeholder={searchPlaceholder}
              className="input-bordered input w-full"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onClick={(e) => e.stopPropagation()}
              disabled={disabled}
            />
          </div>
          <div className="max-h-60 overflow-y-auto">
            {filteredOptions.length > 0 ? (
              <ul className="menu w-full">
                {filteredOptions.map((option) => (
                  <li key={option.key} className="mb-2">
                    <button
                      onClick={() => handleOptionSelect(option)}
                      className={cn(
                        "btn-block btn flex-nowrap justify-between border border-neutral/10 focus:outline-primary/80",
                        "transition-colors duration-300",
                        "text-left",
                        selectedValue === option.value
                          ? "border-primary bg-primary/10 text-primary hover:border-primary hover:bg-primary/20 focus:border-primary focus:bg-primary/20 disabled:border-primary disabled:bg-primary/10 disabled:text-primary"
                          : "bg-transparent hover:bg-mama-gray-100/20 focus:bg-mama-gray-100/20",
                      )}
                      disabled={disabled}
                    >
                      {option.value}

                      <Check
                        className={cn(
                          "hidden sm:block",
                          "transition-opacity delay-75 duration-500",
                          "stroke-primary",
                          selectedValue === option.value
                            ? "opacity-100"
                            : "opacity-0",
                        )}
                      />
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="py-4 text-center text-sm text-mama-default-primary">
                {"No matches found"}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
