import { PlainI18nProps } from "shared/types/i18n";

import { LoadingIndicator } from "./loading-spinner";
import { cn } from "~/util/cn";

export const LoadingScreen: React.FC<{
  message?: PlainI18nProps;
  className?: string;
}> = ({ message = { tx: "general.loading" }, className }) => {
  return (
    <div className={cn("h-dvh grid w-screen place-content-center", className)}>
      <LoadingIndicator as="spinner" className={className} message={message} />
    </div>
  );
};
