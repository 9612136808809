import { cn } from "~/util/cn";

export const AudioControl: React.FC<{ src: string; className?: string }> = ({
  src,
  className,
}) => {
  if (src.indexOf(";") == -1) {
    return null;
  }

  const [dataAudioType] = src.split(";");

  return (
    <audio controls className={cn(className)}>
      <source src={src} type={dataAudioType.split(":")[1]} />
    </audio>
  );
};
