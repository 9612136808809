import i18next from "i18next";
import { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "shared/model/languages";

import { BottomDrawerResponsive } from "../bottom-drawer/responsive";
import { I18nProps } from "../text";
import { Text } from "../text";
import { useTenantConfig } from "~/tenant-settings";
import { cn } from "~/util/cn";
import { FlagOfLanguage } from "~/util/flags";

export const LanguageSelector: React.FC<{
  className?: string;
  breakpoint?: ComponentProps<typeof BottomDrawerResponsive>["breakpoint"];
}> = ({ className, breakpoint = "sm" }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const tenantConfig = useTenantConfig();

  return (
    <BottomDrawerResponsive
      button={({ toggle, isLarge }) =>
        isLarge ? (
          <button className={cn("btn-ghost btn", className)} onClick={toggle}>
            <FlagOfLanguage
              language={language as Language}
              width={20}
              height={20}
            />
            <Text tx={`i18n.languages.${language}` as I18nProps["tx"]} />
          </button>
        ) : (
          <button
            className={cn("btn-ghost btn-square btn", className)}
            onClick={toggle}
          >
            <FlagOfLanguage
              language={language as Language}
              width={20}
              height={20}
            />
          </button>
        )
      }
      breakpoint={breakpoint}
    >
      {({ close }) => (
        <>
          <Text
            tx="i18n.selectLanguage"
            as="h3"
            className="mb-4 text-center text-xl font-semibold text-mama-default-primary"
          />

          <ul className="grid grid-cols-1 gap-3 sm:grid-cols-2">
            {tenantConfig.supportedLanguages.map((supportedLanguage) => (
              <li key={supportedLanguage}>
                <button
                  onClick={() => {
                    i18next.changeLanguage(supportedLanguage);
                    close();
                  }}
                  className={cn(
                    "btn-block btn border border-neutral/10 focus:outline-primary/80",
                    "transition-colors duration-300",
                    language === supportedLanguage
                      ? "border-primary bg-primary/10 hover:border-primary hover:bg-primary/20 focus:border-primary focus:bg-primary/20 disabled:border-primary disabled:bg-primary/10 disabled:text-primary-content"
                      : "bg-transparent hover:bg-mama-gray-100/20 focus:bg-mama-gray-100/20",
                  )}
                >
                  <FlagOfLanguage
                    language={supportedLanguage as Language}
                    width={20}
                    height={20}
                  />
                  <Text
                    tx={
                      `i18n.languages.${supportedLanguage}` as I18nProps["tx"]
                    }
                  />
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
    </BottomDrawerResponsive>
  );
};
