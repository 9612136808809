import React, { useState } from "react";

import { useT } from "../../../../../../../i18n/use-t";
import { ChatOptionComponentProps } from "../../lookup";

export const Choices: React.FC<ChatOptionComponentProps> = ({
  choices,
  sendResponse,
}) => {
  const t = useT();
  const [isBlocked, setIsBlocked] = useState(false);

  return (
    <div className="mx-auto flex w-full max-w-screen-sm flex-wrap items-center gap-2 p-2">
      {choices.map((choice) => (
        <button
          key={choice}
          onClick={() => {
            sendResponse({
              message: t({ tx: choice }),
              selection: [choice],
            });
            setIsBlocked(true);
          }}
          className="btn-primary btn flex-1"
          disabled={isBlocked}
        >
          {isBlocked && (
            <span className="loading loading-ring loading-md mr-2" />
          )}

          {t({ tx: choice })}
        </button>
      ))}
    </div>
  );
};
