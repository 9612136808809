import {
  CH,
  CO,
  DE,
  ES,
  FR,
  IT,
  NL,
  Props,
  TR,
  US,
} from "country-flag-icons/react/3x2";
import { Language } from "shared/model/languages";

import { useT } from "../i18n/use-t";

const flagOfLanguage = {
  [Language.de_CH]: CH,
  [Language.de_DE]: DE,
  [Language.en_US]: US,
  [Language.es_CO]: CO,
  [Language.es_ES]: ES,
  [Language.it_IT]: IT,
  [Language.nl_NL]: NL,
  [Language.tr_TR]: TR,
  [Language.fr_FR]: FR,
};

export const FlagOfLanguage: React.FC<{ language: Language } & Props> = ({
  language,
  ...props
}) => {
  const Flag = flagOfLanguage[language];
  const t = useT();

  return <Flag title={t({ tx: `i18n.languages.${language}` })} {...props} />;
};
