import { t } from "i18next";
import { useCallback, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Language } from "shared/model/languages";
import { EnabledPage } from "shared/model/pages";

import { useTenantConfig } from "../../../tenant-settings";
import { Dropdown, DropdownProps } from "../dropdown";
import { AuthPagesEnum } from "~/auth/constants";
import { FlagOfLanguage } from "~/util/flags";

export type SupportedLanguagesDropdownType = React.FC<{
  className?: string;
  selectedValueClassName?: string;
  value: Language | undefined;
  onChange: (language: Language) => void;
  currentPage?: EnabledPage;
}>;

export const SupportedLanguagesDropdown: SupportedLanguagesDropdownType = ({
  className,
  onChange,
  value,
  currentPage,
  selectedValueClassName,
}) => {
  const tenantConfig = useTenantConfig();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname: fullPathname } = useLocation();
  const languageParams = searchParams.get("language");

  const handleChange = useCallback(
    (lang: Language) => {
      if (!!languageParams) {
        searchParams.delete("language");
        setSearchParams(searchParams);
      }

      onChange(lang);
    },
    [languageParams, onChange, searchParams, setSearchParams],
  );

  const supportedLanguages = useMemo(
    () => tenantConfig?.supportedLanguages,
    [tenantConfig],
  );

  const pageSupportsLanguage = useCallback(
    (language: Language) =>
      !!currentPage && currentPage.supportedLanguages
        ? currentPage.supportedLanguages.includes(language)
        : true,
    [currentPage],
  );

  const languages = useMemo<DropdownProps<Language>["options"]>(
    () =>
      supportedLanguages?.map((lang) => ({
        label: { text: t(`i18n.languages.${lang}`, { lng: lang }) },
        value: lang,
        disabled: {
          isOptionDisabled: !pageSupportsLanguage(lang),
          reasonWhyDisabled: { tx: "i18n.notAvailableInLanguage" },
        },
      })) ?? [],
    [pageSupportsLanguage, supportedLanguages],
  );

  return (
    <Dropdown
      value={value}
      overflowVisible
      options={languages}
      className={className}
      onChange={handleChange}
      disabled={
        languages.length <= 1 ||
        (tenantConfig.isLanguageSelectionDisabled &&
          !(
            fullPathname.endsWith(AuthPagesEnum.MAGIC_SIGN_IN) ||
            fullPathname.endsWith(AuthPagesEnum.SIGN_UP)
          ))
      }
      selectedValueClassName={selectedValueClassName}
      noValueSelected={{ tx: "i18n.selectLanguage" }}
      summaryRenderer={({ language, i18nProps }) => (
        <div className="flex items-center gap-x-2">
          <FlagOfLanguage language={language} width={20} height={20} />{" "}
          {i18nProps.text}
        </div>
      )}
      summaryItem={({ language, i18nProps, onClick }) => (
        <button
          className="btn-ghost btn-block btn-sm btn"
          title={i18nProps.text}
          onClick={onClick}
        >
          <FlagOfLanguage language={language} width={20} height={20} />{" "}
          {i18nProps.text}
        </button>
      )}
    />
  );
};
