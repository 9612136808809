import * as Sentry from "@sentry/react";
import {
  MutationFunction,
  MutationKey,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { TenantIdentifier } from "shared/config";
import { DeploymentEnvironment } from "shared/config/deployment-environments";
import { Disease } from "shared/model/diseases";
import { Organisation } from "shared/model/organisations";

import { usePrivateApiClients } from "./private-api-provider";
import {
  ApiClient,
  ApiClientService,
  ErrorContext,
  ResponseContext,
  TenantIdHeader,
} from "./types/api";
import { FetchError } from "../components/events/fetch-error";
import { RequestError } from "../components/events/request-error";
import { SuccessEvent } from "../components/events/success-event";
import { I18nProps } from "../components/text";
import { useStore } from "../models/helpers";
import { appEnv } from "~/util/env-utils";
interface ApiClientConfig {
  successMessage?: I18nProps;
  trackErrors?: boolean;
}

export const useApiQuery = <
  TClient extends ApiClientService,
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey & Array<unknown> = QueryKey & Array<unknown>,
>(
  client: TClient,
  callApi: (api: ApiClient<TClient>) => Promise<TQueryFnData>,
  queryKey: TQueryKey,
  config: ApiClientConfig = {},
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<TData, TError> => {
  const clientImplementation = useApiClient(client, config, queryKey);

  return useQuery(queryKey, () => callApi(clientImplementation), {
    ...options,
    enabled: options?.enabled,
  });
};

export const useApiMutation = <
  TClient extends ApiClientService,
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  client: TClient,
  getMutationFunction: (
    api: ApiClient<TClient>,
  ) => MutationFunction<TData, TVariables>,
  mutationKey?: MutationKey & Array<unknown>,
  config: ApiClientConfig = {},
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    "mutationKey" | "mutationFn"
  >,
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const clientImplementation = useApiClient(client, config, mutationKey);

  return useMutation(getMutationFunction(clientImplementation), {
    ...options,
    mutationKey,
  });
};

export const toTenantIdHeader = (
  tenantId: TenantIdentifier,
): TenantIdHeader => ({
  mamaDisease: tenantId.disease,
  mamaOrganisation: tenantId.organisation,
});

export const toTenantId = (tenantId: {
  mamaDisease: string;
  mamaOrganisation?: string;
}): TenantIdentifier => ({
  disease: tenantId.mamaDisease as Disease,
  organisation: tenantId.mamaOrganisation as Organisation | undefined,
});

export const toTenantDisease = (tenantId: {
  mamaDisease: string;
  mamaOrganisation?: string;
}): TenantIdentifier => ({
  disease: tenantId.mamaDisease as Disease,
});

const useApiClient = <
  TClient extends ApiClientService,
  EventId extends QueryKey & Array<unknown> = QueryKey &
    Array<unknown> &
    MutationKey,
>(
  client: TClient,
  config: ApiClientConfig,
  eventId?: EventId,
): ApiClient<TClient> => {
  const privateApiContext = usePrivateApiClients();
  const post = usePostMiddleware(config, eventId);
  const showErrors = config.trackErrors || appEnv === DeploymentEnvironment.DEV;

  return useMemo(() => {
    if (client === "backend") {
      const middlewares: ApiClient<"backend">["middleware"] = [];
      if (config.successMessage || showErrors) middlewares.push({ post });
      if (config.trackErrors) middlewares.push({ onError });
      return privateApiContext.backend.withMiddleware(
        ...middlewares,
      ) as ApiClient<TClient>;
    } else if (client === "multiagent") {
      const middlewares: ApiClient<"multiagent">["middleware"] = [];
      if (config.successMessage || showErrors) middlewares.push({ post });
      if (config.trackErrors) middlewares.push({ onError });
      return privateApiContext.multiagent.withMiddleware(
        ...middlewares,
      ) as ApiClient<TClient>;
    } else {
      const middlewares: ApiClient<"landbot">["middleware"] = [];
      if (config.successMessage || showErrors) middlewares.push({ post });
      if (config.trackErrors) middlewares.push({ onError });
      return privateApiContext.landbot.withMiddleware(
        ...middlewares,
      ) as ApiClient<TClient>;
    }
  }, [
    client,
    config.successMessage,
    config.trackErrors,
    post,
    privateApiContext,
    showErrors,
  ]);
};

const usePostMiddleware = <
  EventId extends QueryKey & Array<unknown> = QueryKey &
    Array<unknown> &
    MutationKey,
>(
  config: ApiClientConfig = {},
  eventId?: EventId,
): ((error: ResponseContext) => Promise<void>) => {
  const store = useStore();
  return useCallback(
    async (context) => {
      if (context.response.ok) {
        if (config.successMessage) {
          store.addToastEvent(
            new SuccessEvent(config.successMessage, eventId?.toString()),
          );
        } else if (eventId) {
          store.removeToastEvent(eventId.toString());
        }
      }

      if (!context.response.ok && config.trackErrors) {
        let response: object | undefined = undefined;

        try {
          response = await context.response.json();
        } catch {
          /* empty */
        }

        Sentry.captureException(new RequestError(context, response));
      }
    },
    [config.successMessage, config.trackErrors, eventId, store],
  );
};

const onError = async (context: ErrorContext) => {
  Sentry.captureException(new FetchError(context));
};
