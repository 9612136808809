import { tx } from "shared/types/i18n";
import { SIGNUP_MAXIMUM_AGE, SIGNUP_MINIMUM_AGE } from "shared/utils/constants";

import {
  isOlderThan,
  isYoungerThan,
} from "../../../../../../util/age-comparison";
import { ValidationFunction } from "../../lookup";

export const yearOfBirthValidation: ValidationFunction = (value) => {
  if (Number.isInteger(Number(value)) === false) {
    return {
      ok: false,
      message: { tx: tx("userData.pleaseInputAValidYearOfBirth") },
    };
  }

  if (
    isOlderThan({ year: Number(value), minimumAge: SIGNUP_MINIMUM_AGE }) ===
    false
  ) {
    return {
      ok: false,
      message: {
        tx: tx("userData.youMustBeOlderError"),
        txData: { age: SIGNUP_MINIMUM_AGE },
      },
    };
  }

  if (
    isYoungerThan({ year: Number(value), maximumAge: SIGNUP_MAXIMUM_AGE }) ===
    false
  ) {
    return {
      ok: false,
      message: {
        tx: tx("userData.youMustBeYoungerError"),
        txData: { age: SIGNUP_MAXIMUM_AGE },
      },
    };
  }

  return {
    ok: true,
  };
};
