import { ArrowUp } from "lucide-react";
import { useEffect, useRef, useState } from "react";

import { ChatOptionComponentProps } from "../lookup";
import { SelfAdjustingTextarea } from "~/components/input/self-adjusting-textarea";
import { useT } from "~/i18n/use-t";

export const YearOfBirth: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const [message, setMessage] = useState("");
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  const submit = () => {
    sendResponse({
      message,
      selection: [message],
    });
    setMessage("");
  };

  const t = useT();

  return (
    <div className="mx-auto mb-4 flex w-full max-w-screen-sm flex-col gap-1.5 px-2">
      <div className="flex flex-nowrap items-start gap-2 rounded-xl border border-neutral/10 bg-base-200 p-2.5 pl-4 shadow-xl">
        <SelfAdjustingTextarea
          text={message}
          setText={setMessage}
          placeholder={t({ tx: "userData.dateOfBirth" })}
          className="max-h-40 w-full resize-none appearance-none break-words bg-transparent leading-relaxed text-mama-default-primary caret-mama-default-primary outline-none"
          onEnterPressed={submit}
          autoFocus
          inputMode="numeric"
          canUpdateText={(newText) => /^\d*$/.test(newText)}
        />
        <button
          className="btn-primary btn-square btn-sm btn shadow-md"
          onClick={submit}
          disabled={!message.length}
        >
          <ArrowUp width={18} height={18} />
        </button>
      </div>
    </div>
  );
};
