import { useEffect, useRef } from "react";
import { TranslationFeKey } from "shared/types/translation-key";

import { ChatMessageComponent } from "~/components/chat/generic/chat-message";
import { ChatMessage, ChatRole } from "~/components/chat/types";
import { Text } from "~/components/text";
import { cn } from "~/util/cn";

export type MessageListProps = {
  isChatBusy: boolean;
  chatMessages: ChatMessage[];
  chatLoadingMessage?: TranslationFeKey;
  userChatColors?: { bubbleColor?: string; textColor?: string };
  className?: string;
};

export const MessageList: React.FC<MessageListProps> = ({
  isChatBusy,
  chatMessages,
  chatLoadingMessage,
  userChatColors,
  className,
}) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatMessages[chatMessages.length - 1]?.role === ChatRole.USER) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    chatContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      {chatMessages.map((chatMessage, index) => (
        <div
          key={index}
          ref={index === chatMessages.length - 1 ? chatContainerRef : undefined}
          className={cn(className)}
        >
          <ChatMessageComponent
            message={chatMessage}
            userChatColors={userChatColors}
          />
        </div>
      ))}

      {isChatBusy && (
        <div
          className={cn(
            "flex items-center pb-2 pt-4 animate-slideIn",
            className,
          )}
        >
          <span className="loading loading-ring loading-md mr-2" />
          <Text
            tx={chatLoadingMessage ?? "chat.loading"}
            className="text-sm text-mama-default-primary"
          />
        </div>
      )}
      <div ref={bottomRef} />
    </>
  );
};
