import { User } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import { Settings, X } from "lucide-react";
import React, { FC } from "react";
import { userTracking } from "shared/types/auth";

import { useApiMutation } from "~/api/use-api";
import {
  dirtyGetMaybeCurrentUserData,
  useAuthContext,
} from "~/auth/auth-store";
import { BottomDrawerResponsive } from "~/components/bottom-drawer/responsive";
import { SuccessEvent } from "~/components/events/success-event";
import { Link } from "~/components/link";
import { Text } from "~/components/text";
import { useStore } from "~/models/helpers";
import { useModal } from "~/models/modal-provider";
import { ME_KEY } from "~/types/query-keys";
import { isLocalhost } from "~/util/env-utils";
import { useTenantId } from "~/util/use-active-tenant-id";
import { logActivity } from "~/util/user-log";

const DeleteUserButton: FC<JSX.IntrinsicElements["div"] & { user: User }> = ({
  user,
}) => {
  const queryClient = useQueryClient();
  const tenantId = useTenantId();
  const store = useStore();
  const { signOut } = useAuthContext();
  const { showModal, hideModal, updateModal } = useModal();
  const { mutate: deleteUser } = useApiMutation(
    "backend",
    (api) => () => api.deletUserFromCognito(),
    undefined,
    undefined,
    {
      onSuccess: () => {
        queryClient.removeQueries(ME_KEY(tenantId));
        hideModal();
        signOut?.();
        store.addToastEvent(new SuccessEvent({ tx: "profile.accountDeleted" }));
      },
    },
  );
  return (
    <button
      className="btn-error btn-block btn"
      onClick={() => {
        if (!signOut) {
          alert("Error: no auth implementation.");
          return;
        }

        showModal({
          title: { tx: "profile.deleteAccount" },
          description: {
            tx: "profile.deleteAccountDescription",
            txComponents: {
              SupportEmailLink: <Link />,
            },
          },
          onConfirm: async () => {
            updateModal({
              loading: { isLoading: true },
              description: { text: "" },
            });

            const maybeUserData = await dirtyGetMaybeCurrentUserData();
            if (!maybeUserData) return;
            const { accessToken, email } = maybeUserData;
            logActivity(accessToken, true, {
              triggerSource: userTracking.userDeleted,
              disease: tenantId.disease,
              userId: user.id as string,
              email: email,
              isLocalhost: isLocalhost ? "yes" : "no",
              language: i18next.language,
            });

            deleteUser();
          },
        });
      }}
    >
      <X />
      <Text tx="profile.deleteAccount" />
    </button>
  );
};

export const ManageAccountDrawer: React.FC<{ user: User }> = ({ user }) => {
  return (
    <BottomDrawerResponsive
      breakpoint="sm"
      button={({ open }) => (
        <button className="btn-outline btn-block btn" onClick={open}>
          <Settings width={16} />
          <Text tx="profile.manageAccount" />
        </button>
      )}
    >
      <div className="flex w-full flex-col gap-4 p-4">
        <h3 className="text-lg font-bold">
          <Text tx="profile.manageAccount" />
        </h3>
        <DeleteUserButton user={user} />
      </div>
    </BottomDrawerResponsive>
  );
};
