import { ChevronLeft } from "lucide-react";
import React, { useCallback, useEffect, useMemo } from "react";
import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom";

import { Text } from "../../components/text";
import { useT } from "../../i18n/use-t";
import {
  lastActiveTenantLocalStorageKey,
  lastActiveTenantSessionStorageKey,
} from "../../navigate-to-last-active-tenant";
import { useMamaNavigateState } from "../../navigation/use-mama-navigate-state";
import { useTenantConfig } from "../../tenant-settings";
import {
  RouteWithTitle,
  useMetadataForActiveRoute,
} from "../../util/metadata-for-active-route";
import { useTenantId } from "../../util/use-active-tenant-id";
import { AuthPagesEnum } from "../constants";
import { Header } from "~/components/app-shell/header/base";
import { LanguageSelector } from "~/components/app-shell/language-selector";
import { LogoWide } from "~/components/app-shell/logo";
import { NavbarRoute } from "~/router/tenant-page/nav-links";

export const CognitoLayout: React.FC<{ routes: RouteWithTitle[] }> = ({
  routes,
}) => {
  const settings = useTenantConfig();
  const state = useMamaNavigateState();
  const messageToShow = useMemo(
    () => (state.error || state.info) ?? {},
    [state.error, state.info],
  );

  return (
    <AuthWrapper routes={routes}>
      <div className="mx-auto flex h-full w-full max-w-prose flex-col justify-center gap-4 p-4">
        <img
          className="h-20 self-center object-contain object-bottom"
          src={settings?.authLogo}
          alt="Mama Health"
          loading="eager"
          decoding="async"
        />
        <Text
          as="div"
          {...messageToShow}
          className={`text-balance w-full rounded pt-2 text-center text-lg ${
            state.error ? "text-mama-error" : ""
          }`}
        />
        <Outlet />
      </div>
    </AuthWrapper>
  );
};

export const AuthWrapper: React.FC<
  React.PropsWithChildren<{
    routes: RouteWithTitle[];
    showLogo?: boolean;
  }>
> = ({ children, routes, showLogo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const tenantId = useTenantId();
  const { pathname } = useLocation();
  const t = useT();

  const canNavigateToRoot = useMemo(
    () => new URLSearchParams(location.search).toString() === "",
    [location.search],
  );

  const removeLastActiveTenant = useCallback(() => {
    localStorage.removeItem(lastActiveTenantLocalStorageKey);
    sessionStorage.removeItem(lastActiveTenantSessionStorageKey);
  }, []);

  const isOrganisationSignUp = useMemo(
    () =>
      location.pathname.includes(AuthPagesEnum.SIGN_UP) &&
      tenantId.organisation,
    [location.pathname, tenantId.organisation],
  );

  useEffect(() => {
    if (isOrganisationSignUp) {
      navigate(AuthPagesEnum.SIGN_IN);
    }
  }, [isOrganisationSignUp, navigate]);

  useMetadataForActiveRoute(
    useMemo(
      () =>
        routes.map(
          (route): NavbarRoute => ({
            path: route.path,
            position: "start",
            title: t(route.title),
            isActive: !!matchPath(pathname, route.path),
          }),
        ),
      [pathname, routes, t],
    ),
  );

  return (
    <div className="grid h-full w-full grid-cols-1 grid-rows-[4.5rem_calc(100%-4.5rem)]">
      <Header
        left={
          canNavigateToRoot && (
            <button
              title="back"
              className="btn-ghost btn-square btn justify-self-start"
              onClick={() => {
                removeLastActiveTenant();
                navigate("/");
              }}
            >
              <ChevronLeft />
            </button>
          )
        }
        middle={showLogo && <LogoWide />}
        right={<LanguageSelector />}
      />
      <div>{children}</div>
    </div>
  );
};
