import React from "react";
import { PlainI18nProps } from "shared/types/i18n";

import { i18nPropsHaveContent } from "../../i18n/i18n-props-have-content";
import { useT } from "../../i18n/use-t";
import { cn } from "~/util/cn";

export const FormInput: React.FC<
  React.PropsWithChildren<{
    error: PlainI18nProps;
    title?: PlainI18nProps;
    labelClassName?: string;
    isOptional?: boolean;
  }>
> = ({ error, title, children, labelClassName, isOptional }) => {
  const t = useT();

  const hasTitle = i18nPropsHaveContent(title);
  const hasError = i18nPropsHaveContent(error);

  return (
    <label
      className={cn(
        "form-control w-full",
        hasError && "bg-error bg-opacity-25",
      )}
    >
      {(hasTitle || isOptional) && (
        <div className={cn("label text-mama-default-primary", labelClassName)}>
          {hasTitle && <span className="label-text ">{t(title)}</span>}

          {isOptional && (
            <span className="label-text-alt">
              {` (${t({
                tx: "general.optional",
              })})`}
            </span>
          )}
        </div>
      )}

      {children}

      {hasError && (
        <div className={cn("label text-mama-default-primary", labelClassName)}>
          <span className="label-text-alt">{t(error)}</span>
        </div>
      )}
    </label>
  );
};
