import { useEffect, useState } from "react";

import { resolveBreakpoint } from "~/util/breakpoints";
import { cn } from "~/util/cn";
import { useWindowDimensions } from "~/util/hooks";

type HeaderContext = {
  isLarge: boolean;
};

export const Header: React.FC<{
  left?: React.ReactNode | ((ctx: HeaderContext) => React.ReactNode);
  middle?: React.ReactNode | ((ctx: HeaderContext) => React.ReactNode);
  right?: React.ReactNode | ((ctx: HeaderContext) => React.ReactNode);
  breakpoint?: number | "sm" | "md" | "lg" | "xl" | "2xl";
  className?: string;
}> = ({ left, middle, right, breakpoint = "sm", className }) => {
  const { width } = useWindowDimensions();
  const [isLarge, setIsLarge] = useState(
    width >= resolveBreakpoint(breakpoint),
  );

  useEffect(() => {
    setIsLarge(width >= resolveBreakpoint(breakpoint));
  }, [width, breakpoint]);

  return (
    <header
      className={cn(
        "grid grid-cols-[1fr_3fr_1fr] grid-rows-1 p-3",
        "border-b border-neutral/10 bg-mama-white",
        className,
      )}
    >
      <div className="place-self-start">
        {typeof left == "function" ? left({ isLarge }) : left}
      </div>
      <div className="place-self-center">
        {typeof middle == "function" ? middle({ isLarge }) : middle}
      </div>
      <div className="place-self-end">
        {typeof right == "function" ? right({ isLarge }) : right}
      </div>
    </header>
  );
};
