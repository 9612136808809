import { ArrowUp } from "lucide-react";
import React, { useRef, useState } from "react";

import {
  Attachment,
  AttachmentManager,
  ButtonAttachments,
} from "./attachments";
import { AudioControl } from "./audio-control";
import { ButtonRecording, ButtonRecordingRef } from "./recording";
import { SelfAdjustingTextarea } from "./self-adjusting-textarea";
import { cn } from "~/util/cn";

export const SelfAdjustingTextareaWithAttachments: React.FC<{
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
  onSubmit: (ctx: {
    payload: {
      text: string;
      base64Audio: string;
      attachments: Attachment[];
    };
    reset: () => void;
  }) => void;
  autoFocus?: boolean;
}> = ({ text, setText, className, onSubmit, autoFocus }) => {
  const [base64Audio, setBase64Audio] = useState("");
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const buttonRecordingRef = useRef<ButtonRecordingRef>(null);

  const handleSubmit = () => {
    onSubmit({
      payload: {
        text,
        base64Audio,
        attachments,
      },
      reset() {
        setText("");
        setBase64Audio("");
        buttonRecordingRef.current?.removeRecording();
        setAttachments([]);
      },
    });
  };

  return (
    <div
      className={cn(
        "rounded-2xl border border-neutral/10 bg-base-200 p-4",
        className || "mx-auto w-full max-w-screen-sm shadow-xl",
      )}
    >
      {attachments.length > 0 && (
        <AttachmentManager
          attachments={attachments}
          setAttachments={setAttachments}
        />
      )}

      {base64Audio.length == 0 && (
        <SelfAdjustingTextarea
          text={text}
          setText={setText}
          className="max-h-48 w-full resize-none appearance-none break-words bg-transparent leading-relaxed text-mama-default-primary caret-mama-default-primary outline-none sm:max-h-96"
          onEnterPressed={handleSubmit}
          autoFocus={autoFocus}
        />
      )}
      <div className="flex items-center justify-between">
        <ButtonAttachments
          attachments={attachments}
          setAttachments={setAttachments}
          className="mr-2"
        />

        {base64Audio.length > 0 && (
          <AudioControl src={base64Audio} className="ml-auto" />
        )}
        <ButtonRecording
          ref={buttonRecordingRef}
          audio={base64Audio}
          onAudioCreated={setBase64Audio}
          onAudioRemoved={() => setBase64Audio("")}
          className="ml-auto mr-2"
        />

        <button
          className="btn-primary btn-square btn-sm btn shadow-md"
          onClick={handleSubmit}
          disabled={
            text.length == 0 &&
            base64Audio.length == 0 &&
            attachments.length == 0
          }
        >
          <ArrowUp width={18} height={18} />
        </button>
      </div>
    </div>
  );
};
