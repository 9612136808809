/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ConversationWithMessages,
  FrontendQuestionNodeInfo,
  MessageWithType,
  QuestionNodeInfo,
  WebSocketRequest,
  WebSocketResponseEventData,
} from "shared/model/websocket/schema";
import { TranslationFeKey } from "shared/types/translation-key";
import { SECONDS_TO_SHOW_SIGNUP_STUCK_RESET } from "shared/utils/constants";

import { PUBLIC_CHAT_LOCAL_STORAGE_KEY } from "./ai-public-chat";
import { ChatFallbackLookup } from "./lookup";
import { MessageList } from "./message-list";
import { SignUpDrawer } from "./signup";
import { ValidationFunction } from "../lookup";
import { IUseWebSocket } from "~/api/types/websocket";
import { useToasts } from "~/components/app-shell/providers/toasts-provider/toasts-provider";
import { ChatCompletion } from "~/components/chat/generic/chat-completion";
import {
  ChatMessage,
  ChatRole,
  ChatUserMediaRecord,
} from "~/components/chat/types";
import { Link } from "~/components/link";
import { LoadingScreen } from "~/components/loading-screen";
import { Text } from "~/components/text";
import { MimeMainType } from "~/types/mime-main-type";
import { cn } from "~/util/cn";
import { useTenantId } from "~/util/use-active-tenant-id";

export type ConversationProps = {
  chatDrawerComponents: ChatFallbackLookup;
  useAgnosticWebSocket: AgnosticWebSocket;
  conversation: ConversationWithMessages;
  shouldShowChatCompletion: boolean;
  userChatColors?: { bubbleColor?: string; textColor?: string };
  triggerSignUp?: (props: { email: string; name: string }) => Promise<boolean>;
};

export type MessageProps = {
  message: string;
  selection: string[];
  base64Images?: string[];
  base64Audio?: string;
};

export type OnMessageSendFunction = (messageProps: MessageProps) => {
  hasResponseBeenSent: boolean;
};

export type EmailNameTuple = {
  email?: string;
  name?: string;
};

export const Conversation: React.FC<ConversationProps> = ({
  chatDrawerComponents,
  useAgnosticWebSocket,
  conversation,
  shouldShowChatCompletion,
  userChatColors,
  triggerSignUp,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { disease } = useTenantId();
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [chatLoadingMessage, setChatLoadingMessage] =
    useState<TranslationFeKey>();
  const [signUpButtonHasTimeout, setSignUpButtonHasTimeout] = useState(false);
  const validationFunctionRef = useRef<ValidationFunction | undefined>();
  const emailNameTupleRef = useRef<EmailNameTuple>({});
  const lastMessage = useMemo(() => chatMessages.at(-1), [chatMessages]);
  const currentFeKeyQuestionNodeInfo: FrontendQuestionNodeInfo = useMemo(
    () => lastMessage?.questionNodeInfo ?? { type: "QUESTION" },
    [lastMessage],
  );

  useEffect(
    function SetUpChatMessagesOnInitialLoad() {
      const lChatMessages = conversation.messages.map(
        transformMessageToChatMessage,
      );
      emailNameTupleRef.current = lChatMessages.reduce(
        maybeExtractEmailNameTuple,
        {},
      );
      setChatMessages(lChatMessages);
    },
    [conversation],
  );

  const isAtSignupStepRef = useMemo(
    () => ({
      get current() {
        const { email, name } = emailNameTupleRef.current;
        return triggerSignUp && Boolean(email && name);
      },
    }),
    [triggerSignUp],
  );

  const maybeTriggerSignUp = useCallback(async () => {
    if (!triggerSignUp || !isAtSignupStepRef.current) return;

    const { email, name } = emailNameTupleRef.current;
    if (!(email && name)) return;

    const isSignupSuccessful = await triggerSignUp({ email, name });

    if (isSignupSuccessful) {
      setSignUpButtonHasTimeout(true);
    }
  }, [isAtSignupStepRef, triggerSignUp]);

  const appendMessageToChat = useCallback((message: ChatMessage) => {
    emailNameTupleRef.current = maybeExtractEmailNameTuple(
      emailNameTupleRef.current ?? {},
      message,
    );
    message.isNew = true;
    setChatMessages((prev) => [...prev, message]);
  }, []);

  const popMessageFromChat = () => setChatMessages((prev) => prev.slice(0, -1));

  const [websocketConnectionKey, setWebsocketConnectionKey] = useState(
    `${disease}-${conversation.id}-${Date.now()}`,
  );

  useEffect(() => {
    const newConnectionKey = `${disease}-${conversation.id}-${Date.now()}`;
    setWebsocketConnectionKey(newConnectionKey);
  }, [disease, conversation.id]);

  const { sendMessage: sendWebSocketMessage, isReady: isWebSocketReady } =
    useAgnosticWebSocket({
      key: websocketConnectionKey,
      query: {
        language,
        disease,
        conversation_id: conversation.id,
      },
      onResponse: async (event) => {
        if (!event) return;

        const message = event?.data.message;
        const key = event?.data.key;
        setChatLoadingMessage(key);

        if (event.type == "message" && !event.data.success) {
          toasts.addToast({
            component: (
              <Text
                text={
                  event.data.error?.message ?? "something unexpected happened"
                }
                className="text-sm"
              />
            ),
            variant: "error",
            autoClose: 3000,
            closable: true,
          });
          popMessageFromChat();
          return;
        }

        if (!message) return;

        appendMessageToChat(
          transformMessageToChatMessage({
            ...message,
            metadata: event.data.metadata,
          }),
        );
        setChatLoadingMessage(undefined);
      },
    });

  const isChatBusy =
    lastMessage?.role === ChatRole.USER && !isAtSignupStepRef.current;

  const toasts = useToasts();

  const onSendMessage: OnMessageSendFunction = useCallback(
    ({ message, base64Images, base64Audio, selection }) => {
      if (isChatBusy) return { hasResponseBeenSent: false };

      let messageToSend = message;
      if (
        !messageToSend &&
        (!base64Images || !base64Images.length) &&
        !base64Audio
      )
        return { hasResponseBeenSent: false };

      const media = combineUploadsToMedia(base64Images, base64Audio);
      if (base64Audio) {
        messageToSend = "";
      }

      if (validationFunctionRef.current) {
        const { ok, message: errorMessage } =
          validationFunctionRef.current(messageToSend);

        if (!ok) {
          console.log(errorMessage);
          toasts.addToast({
            component: <Text {...errorMessage} className="text-sm" />,
            variant: "error",
            autoClose: 3000,
            closable: true,
          });

          return { hasResponseBeenSent: false };
        }
      }

      appendMessageToChat({
        media: media,
        role: ChatRole.USER,
        content: {
          text: messageToSend,
        },
        questionNodeInfo: currentFeKeyQuestionNodeInfo,
      });

      sendWebSocketMessage({
        type: "message",
        data: {
          questionNodeInfo: currentFeKeyQuestionNodeInfo,
          metadata: {
            selections: selection,
          },
          media: {
            audio: base64Audio,
            images: base64Images,
          },
          message: messageToSend,
        },
      });

      maybeTriggerSignUp();

      return { hasResponseBeenSent: true };
    },
    [
      appendMessageToChat,
      currentFeKeyQuestionNodeInfo,
      isChatBusy,
      maybeTriggerSignUp,
      sendWebSocketMessage,
      toasts,
    ],
  );

  const Component = useMemo(() => {
    if (!currentFeKeyQuestionNodeInfo) return null;

    const [UnInitializedDrawerComponent, validation] = chatDrawerComponents.get(
      [
        currentFeKeyQuestionNodeInfo.type,
        currentFeKeyQuestionNodeInfo?.subtype,
      ],
    );
    validationFunctionRef.current = validation;

    const DrawerComponent = (
      <UnInitializedDrawerComponent
        sendResponse={onSendMessage}
        choices={currentFeKeyQuestionNodeInfo?.choices ?? []}
        mediaSrc={currentFeKeyQuestionNodeInfo.mediaSrc}
        mediaAlt={currentFeKeyQuestionNodeInfo.mediaAlt}
      />
    );

    return shouldShowChatCompletion ? (
      <ChatCompletion conversationId={conversation.id}>
        {DrawerComponent}
      </ChatCompletion>
    ) : (
      DrawerComponent
    );
  }, [
    chatDrawerComponents,
    conversation.id,
    currentFeKeyQuestionNodeInfo,
    onSendMessage,
    shouldShowChatCompletion,
  ]);

  const isLoading = !isWebSocketReady || !chatMessages;

  return isLoading || !Component || !currentFeKeyQuestionNodeInfo ? (
    <LoadingScreen message={{ tx: "chat.loadingInitialChat" }} />
  ) : (
    <div className="flex h-full flex-col justify-between">
      <main className="h-full overflow-y-auto bg-gradient-to-b from-base-200/20 to-transparent px-3 py-5">
        <MessageList
          chatMessages={chatMessages}
          isChatBusy={isChatBusy}
          chatLoadingMessage={chatLoadingMessage}
          userChatColors={userChatColors}
          className="mx-auto max-w-screen-sm"
        />
      </main>
      <menu className="sticky bottom-0 left-0 right-0">
        {isAtSignupStepRef.current && !signUpButtonHasTimeout ? (
          <LoadingState />
        ) : isAtSignupStepRef.current && signUpButtonHasTimeout ? (
          <SignUpDrawer
            hasInitialTimeout={signUpButtonHasTimeout}
            reTriggerEmail={maybeTriggerSignUp}
          />
        ) : (
          Component
        )}
      </menu>
    </div>
  );
};

const LoadingState: React.FC<{
  showResetAfterMs?: number;
}> = ({ showResetAfterMs = SECONDS_TO_SHOW_SIGNUP_STUCK_RESET * 1_000 }) => {
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const [showReset, setShowReset] = useState(false);
  useEffect(() => {
    timeout.current = setTimeout(() => {
      setShowReset(true);
    }, showResetAfterMs);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };
  }, [showResetAfterMs]);

  return (
    <div className="sticky inset-x-0 bottom-0">
      <div
        className={cn(
          "absolute bottom-4 right-3 flex items-center rounded-lg border-neutral/10 bg-base-200 px-3 py-1.5 text-base-content shadow-lg animate-fadeIn",
          showReset ? "-translate-y-14" : "translate-y-0",
          "transition-transform duration-500 ease-in-out",
        )}
      >
        <span className="loading loading-ring loading-md mr-2" />
        <Text
          tx="general.loading"
          className="text-xs font-medium text-mama-default-primary"
        />
      </div>
      <div
        className={cn(
          "border-t border-neutral/10 bg-white p-2",
          showReset ? "opacity-100" : "opacity-0",
          "transition-opacity delay-75 duration-500 ease-in-out",
        )}
      >
        <Text
          as="div"
          className="text-balance text-center text-mama-default-primary"
          tx="graph.text.signup.restartChat"
          txComponents={{
            restartChat: (
              <Link
                onClick={async () => {
                  localStorage.removeItem(PUBLIC_CHAT_LOCAL_STORAGE_KEY);
                  location.reload();
                }}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

type OnWebSocketResponse = (
  event?: MessageEvent<WebSocketResponseEventData>,
) => Promise<void> | void;

export type AgnosticWebSocket = (params: {
  key?: string;
  query: {
    language: string;
    disease: string;
    conversation_id: string;
  };
  onResponse: OnWebSocketResponse;
}) => IUseWebSocket<WebSocketRequest>;

const combineUploadsToMedia = (
  base64Images?: string[],
  base64Audio?: string,
): ChatUserMediaRecord[] => [
  ...(base64Audio ? [{ mainType: MimeMainType.AUDIO, path: base64Audio }] : []),
  ...(base64Images
    ? base64Images.map((b64Image) => ({
        mainType: MimeMainType.IMAGE,
        path: b64Image,
      }))
    : []),
];

const maybeExtractEmailNameTuple = (
  emailNameTuple: EmailNameTuple,
  chatMessage: ChatMessage,
): EmailNameTuple => {
  if (
    chatMessage.role === ChatRole.USER &&
    chatMessage.questionNodeInfo?.type === "SIGNUP"
  ) {
    emailNameTuple.email = chatMessage.content.text;
  }

  if (
    chatMessage.role === ChatRole.USER &&
    chatMessage.questionNodeInfo?.subtype === "NAME"
  ) {
    emailNameTuple.name = chatMessage.content.text;
  }

  return emailNameTuple;
};

const transformMessageToChatMessage = ({
  media,
  contentLocalized,
  role,
  questionNodeInfo,
  metadata,
}: MessageWithType): ChatMessage => {
  const isAudio = media?.some((m) => m.mainType === "audio");

  return {
    content: {
      text: !isAudio ? contentLocalized : "",
    },
    role: role as ChatRole,
    questionNodeInfo: replaceChoicesWithTranslationKeys(questionNodeInfo),
    media,
    sources: metadata?.sources,
  };
};

const replaceChoicesWithTranslationKeys = (
  questionNodeInfo: QuestionNodeInfo,
): FrontendQuestionNodeInfo => {
  const maybeChoiceFeKeys = questionNodeInfo.choices?.map(
    (choice) =>
      `graph.choice.${questionNodeInfo.subtype}.${choice}` as TranslationFeKey,
  );

  return {
    type: questionNodeInfo.type,
    subtype: questionNodeInfo.subtype,
    choices: maybeChoiceFeKeys,
    mediaSrc: questionNodeInfo.mediaSrc,
    mediaAlt: questionNodeInfo.mediaAlt,
  };
};
