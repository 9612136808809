import React, { ElementType, PropsWithChildren, useMemo } from "react";
import { Trans } from "react-i18next";
import { PlainI18nProps } from "shared/types/i18n";

import { Link } from "./link";

export type TextProps = PropsWithChildren<
  {
    as?: ElementType;
  } & I18nProps &
    React.HTMLAttributes<HTMLSpanElement>
>;

export type I18nComponents =
  | React.ReactElement[]
  | { [tagName: string]: React.ReactElement };

export interface I18nProps extends PlainI18nProps {
  /**
   * Optional components to be used to style the translated text when `tx`
   * is being used.
   */
  txComponents?: I18nComponents;
}

const defaultTxComponents = {
  Bold: <b />,
  Italic: <i />,
  Deleted: <del />,
  Link: <Link target="_blank" />,
};

export const Text: React.FC<TextProps> = ({
  text,
  tx,
  txUnchecked,
  txComponents,
  txData,
  as: Component = "span",
  children,
  className,
  ...rest
}) => {
  const resolvedTx = useMemo(() => tx || txUnchecked, [tx, txUnchecked]);
  return text || resolvedTx ? (
    <Component className={className} {...rest}>
      {resolvedTx ? (
        <Trans
          i18nKey={resolvedTx}
          values={txData}
          defaults={text}
          components={{ ...defaultTxComponents, ...txComponents }}
        />
      ) : (
        text
      )}
      {children}
    </Component>
  ) : null;
};
