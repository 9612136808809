import { CircleX, Info, X } from "lucide-react";
import { useEffect, useRef, useState } from "react";

import { cn } from "~/util/cn";

export type ToastProps = {
  id: string;
  component: React.ReactNode;
  autoClose?: number | false;
  variant?: "info" | "error";
  closable?: boolean;
  onRemove: () => void;
};

export type ToastPropsProvidable = {
  component: ToastProps["component"];
  autoClose?: ToastProps["autoClose"];
  variant?: ToastProps["variant"];
  closable?: ToastProps["closable"];
};

export const Toast: React.FC<ToastProps> = ({
  id,
  component,
  autoClose = 3000,
  onRemove,
  variant = "info",
  closable = true,
}) => {
  const [showFadeOutClass, setShowFadeOutClass] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (autoClose) {
      const totalDuration = autoClose;
      const interval = 100;
      const totalSteps = totalDuration / interval;

      const timer = setInterval(() => {
        setProgress((prev) => {
          if (prev >= totalSteps) {
            clearInterval(timer);
            return totalSteps;
          }
          return prev + 1;
        });
      }, interval);

      const fadeOutTimer = setTimeout(() => {
        setShowFadeOutClass(true);
      }, totalDuration);

      return () => {
        clearInterval(timer);
        clearTimeout(fadeOutTimer);
      };
    }
  }, [autoClose]);

  const ref = useRef<HTMLOutputElement>(null);
  useEffect(() => {
    if (showFadeOutClass) {
      const currentRef = ref.current;
      const handleAnimationEnd = () => {
        onRemove();
      };
      currentRef?.addEventListener("animationend", handleAnimationEnd);
      return () => {
        currentRef?.removeEventListener("animationend", handleAnimationEnd);
      };
    }
  }, [showFadeOutClass, onRemove]);

  return (
    <output
      ref={ref}
      id={id}
      role="status"
      className={cn(
        "pointer-events-auto",
        "ml-auto w-full max-w-lg",
        "rounded-lg border p-2 shadow-md",
        showFadeOutClass ? "animate-fadeOut" : "animate-slideIn",
        variant === "error"
          ? "border-error/10 bg-error text-error-content"
          : "border-neutral/10 bg-base-200 text-base-content",
      )}
    >
      <div className="btn-ghost btn-sm btn-square btn pointer-events-none float-left mr-2">
        {variant === "error" ? <CircleX width={16} /> : <Info width={16} />}
      </div>

      {closable && (
        <div className="group isolate float-right ml-2 grid grid-cols-1 grid-rows-1">
          {autoClose && (
            <div
              aria-label="This alert auto closes"
              className="radial-progress z-0 col-start-1 row-start-1 opacity-50 transition-opacity duration-500 ease-in-out focus-within:opacity-100 group-hover:opacity-100"
              style={
                {
                  "--value": (progress / (autoClose / 1000)) * 10,
                  "--size": "2rem",
                  "--thickness": "0.2rem",
                } as React.CSSProperties
              }
              aria-valuenow={(progress / (autoClose / 1000)) * 10}
              role="progressbar"
            />
          )}

          <button
            type="button"
            className="btn-ghost btn-sm btn-circle btn z-10 col-start-1 row-start-1"
            onClick={() => setShowFadeOutClass(true)}
          >
            <X width={16} />
          </button>
        </div>
      )}

      <div className="pt-1 text-sm">{component}</div>
    </output>
  );
};
