import { GraduationCap, SkipForward } from "lucide-react";
import React from "react";

import { useT } from "../../../../../../i18n/use-t";
import { ChatTutorial } from "../../../../chat-tutorial";
import { ChatOptionComponentProps } from "../lookup";
import { BottomDrawerResponsive } from "~/components/bottom-drawer/responsive";
import { Text } from "~/components/text";

export const IntroductionModal: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const t = useT();

  return (
    <div className="grid w-full grid-cols-2 gap-3">
      <BottomDrawerResponsive
        button={({ open }) => (
          <button
            type="button"
            onClick={open}
            className="btn-ghost btn border border-neutral/10"
          >
            <GraduationCap width={16} />
            <Text tx="chat.showMore" />
          </button>
        )}
      >
        <ChatTutorial />
      </BottomDrawerResponsive>
      <button
        type="button"
        onClick={() => {
          sendResponse?.({ message: t({ tx: "chat.skip" }), selection: [] });
        }}
        className="btn-primary btn"
      >
        <SkipForward width={16} />
        <Text tx="chat.skip" />
      </button>
    </div>
  );
};
