import { useEffect, useMemo } from "react";
import { RouteObject } from "react-router-dom";
import { PlainI18nProps } from "shared/types/i18n";

import { useDestructable } from "./hooks";
import { i18nPropsHaveContent } from "../i18n/i18n-props-have-content";
import { T, useT } from "../i18n/use-t";
import { TenantConfig, useTenantConfig } from "../tenant-settings";
import { I18nProps } from "~/components/text";
import { NavbarRoute } from "~/router/tenant-page/nav-links";

export type RouteWithTitle = RouteObject & {
  title: PlainI18nProps;
  path: string;
};

export const useMetadataForActiveRoute = (routes: NavbarRoute[] = []): void => {
  const activeRoute = useMemo(
    () =>
      (routes || [])
        .flatMap((route: NavbarRoute) =>
          "path" in route ? [route] : route.childRoutes,
        )
        .find((route) => route.isActive),
    [routes],
  );
  useMetadata({ text: activeRoute?.title });
};

export const useMetadata = (title?: PlainI18nProps): void => {
  const t = useT();
  const config = useTenantConfig();

  useEffect(() => {
    document.title =
      (i18nPropsHaveContent(title) ? t(title) + " - " : "") +
      t({ tx: config.tenantNameTx });
  }, [config.tenantNameTx, t, title]);

  useEffect(() => {
    if (config?.favIcon) {
      document
        .getElementById("link-icon")
        ?.setAttribute("href", config.favIcon);
    }
  }, [config?.favIcon]);

  useEffect(() => {
    if (config) {
      document
        .getElementById("meta-description")
        ?.setAttribute("content", t({ tx: config.metaDescriptionTx }));
    }
  }, [config, t]);

  useDestructable(
    (..._) => {
      if (config) {
        const url = URL.createObjectURL(new Blob([manifestSource(config, t)]));
        document.getElementById("meta-manifest")?.setAttribute("href", url);
        return url;
      }
    },
    (url) => url && URL.revokeObjectURL(url),
    [config, t],
  );
};

export const useDefaultMetadata = (config: {
  title: I18nProps;
  favIcon: string;
}): void => {
  const t = useT();

  useEffect(() => {
    if (config?.favIcon) {
      document
        .getElementById("link-icon")
        ?.setAttribute("href", config.favIcon);
    }
  }, [config?.favIcon]);

  useEffect(() => {
    document.title = t(config.title);
  }, [config, t]);
};

const manifestSource = (
  { tenantNameTx, favIcon, favIconHighres, theme }: TenantConfig,
  t: T,
): string =>
  JSON.stringify({
    short_name: t({ tx: tenantNameTx }),
    name: t({ tx: tenantNameTx }),
    icons: [
      {
        src: location.origin + favIcon,
        sizes: "64x64 32x32 24x24 16x16",
      },
      {
        src: location.origin + favIconHighres,
        sizes: "192x192 512x512",
      },
    ],
    start_url: location.origin,
    display: "standalone",
    theme_color: theme.daisyTheme.primary,
    background_color: theme.daisyTheme["base-100"],
  });
