import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { pathSegmentOfEnum } from "shared/config/base-urls";
import { allDiseaseTypes, Disease } from "shared/model/diseases";
import { TranslationFeKey } from "shared/types/translation-key";

import { Header } from "./components/app-shell/header/base";
import { LogoWide } from "./components/app-shell/logo";
import { Text } from "./components/text";
import { useT } from "./i18n/use-t";
import { useDefaultMetadata } from "./util/metadata-for-active-route";

export const SelectDisease: React.FC = () => {
  const [params] = useSearchParams();
  useDefaultMetadata({
    title: { text: "mama health" },
    favIcon: "/resources/mama-favicon-highres.png",
  });

  const navigateToDisease = useCallback((disease: Disease) => {
    location.href = `${location.origin}/${pathSegmentOfEnum(disease)}`;
  }, []);

  useEffect(() => {
    const maybeDisease = params.get("disease");
    if (maybeDisease && isDisease(maybeDisease))
      navigateToDisease(maybeDisease);
  }, [navigateToDisease, params]);

  return (
    <div className="h-dvh grid w-full grid-cols-1 grid-rows-[4.5rem_calc(100%-4.5rem)]">
      <Header middle={<LogoWide />} />
      <div className="text-balance flex flex-col items-center gap-3 px-2 pt-10">
        <Text
          tx="selectDisease.explanation_1"
          className="max-w-screen-sm text-center text-mama-default-primary"
        />

        <main className="px-4 pb-10 sm:grid sm:place-content-center">
          <Text
            tx="selectDisease.selectDisease"
            as="h1"
            className="mb-1 mt-4 text-center text-3xl font-semibold text-mama-default-primary"
          />

          <ul className="mt-4 grid w-full max-w-screen-sm grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-3">
            {allDiseaseTypes.map((disease) => (
              <DiseaseButton
                key={disease}
                disease={disease}
                onClick={navigateToDisease}
              />
            ))}
          </ul>
        </main>
      </div>
    </div>
  );
};

const DiseaseButton: React.FC<{
  disease: Disease;
  onClick: (disease: Disease) => void;
}> = ({ disease, onClick }) => {
  const t = useT();
  const diseaseName = `disease.${formatDiseaseName(
    disease,
  )}.settings.name` as TranslationFeKey;

  return (
    <button
      title={t({ tx: diseaseName })}
      onClick={() => onClick(disease)}
      className="btn-block btn border border-neutral/10 hover:bg-mama-gray-100/20 focus:bg-mama-gray-100/20 focus:outline-primary/80"
    >
      <Text tx={diseaseName} />
    </button>
  );
};

const formatDiseaseName = (input: string): string =>
  input
    .split("_")
    .map((word) => word.toLocaleLowerCase())
    .join("-");

const isDisease = (maybeDisease: string): maybeDisease is Disease =>
  Object.values(Disease).includes(maybeDisease as Disease);
