import { useEffect, useState } from "react";

import { cn } from "~/util/cn";

export const TextStream: React.FC<{
  staticText: string;
  step?: number;
  className?: string;
}> = ({ staticText, step = 50, className }) => {
  const words = staticText.split(/[\s\n]+/);
  const [revealedWords, setRevealedWords] = useState(
    new Array(words.length).fill(false),
  );

  useEffect(() => {
    const revealWords = () => {
      for (let i = 0; i < words.length; i++) {
        setTimeout(() => {
          setRevealedWords((prev) => {
            const newRevealed = [...prev];
            newRevealed[i] = true;
            return newRevealed;
          });
        }, i * step);
      }
    };

    revealWords();
  }, [words.length, step]);

  return (
    <div className={cn("flex flex-wrap gap-[0.2rem]", className)}>
      {words.map((word, index) => (
        <span
          key={index}
          className={cn(
            "transition-opacity duration-100 ease-in-out",
            revealedWords[index] ? "opacity-100" : "opacity-0",
          )}
        >
          {word}
        </span>
      ))}
    </div>
  );
};
