import { ReactNode, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  assertIsValidTenantIdentifier,
  TenantIdentifier,
  tenantIdEquals,
} from "shared/config";
import { basePathOfTenant } from "shared/config/base-urls";

import { SetLastActiveTenantRequest } from "./api/generated/backend";
import { useApiMutation, useApiQuery } from "./api/use-api";
import { LoadingScreen } from "./components/loading-screen";
import { queryClient } from "./query-client";
import { SelectDisease } from "./select-disease";
import { LAST_ACTIVE_TENANT_KEY } from "./types/query-keys";
import { useOptionalTenantId } from "./util/use-active-tenant-id";

export const NavigateToLastActiveTenant: React.FC<{
  hasApiAccess: boolean;
  displayWhenNoTenant?: ReactNode;
}> = ({ hasApiAccess }) => {
  const { lastActiveTenant, loading } = useLastActiveTenant(hasApiAccess);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  useEffect(() => {
    if (lastActiveTenant) {
      navigate(
        basePathOfTenant(lastActiveTenant) +
          location.pathname +
          location.search +
          location.hash,
        {
          replace: true,
          state,
        },
      );
    }
  }, [navigate, location, lastActiveTenant, state]);

  return loading ? (
    <LoadingScreen />
  ) : !lastActiveTenant ? (
    <SelectDisease />
  ) : null;
};

type LastActiveTenant = {
  lastActiveTenant: TenantIdentifier | undefined;
  loading: boolean;
};

export const useLastActiveTenantSaver = (hasApiAccess: boolean): void => {
  useLastActiveTenant(hasApiAccess);
};

const useLastActiveTenant = (hasApiAccess: boolean): LastActiveTenant => {
  const activeTenantId = useOptionalTenantId();
  const lastActiveTenantFromDb = hasApiAccess
    ? useLastActiveTenantFromDb(hasApiAccess)
    : undefined;
  const lastActiveTenantFromLocalStorage =
    useLastActiveTenantFromLocalStorage();
  const lastActiveTenantFromSessionStorage =
    useLastActiveTenantFromSessionStorage();
  const lastActiveTenantFromState = useLastActiveTenantFromState();

  return activeTenantId
    ? {
        lastActiveTenant: activeTenantId,
        loading: false,
      }
    : lastActiveTenantFromState ??
        lastActiveTenantFromSessionStorage ??
        lastActiveTenantFromLocalStorage ??
        lastActiveTenantFromDb ?? {
          lastActiveTenant: undefined,
          loading: false,
        };
};
const useLastActiveTenantFromState = (): LastActiveTenant | undefined => {
  const activeTenantId = useOptionalTenantId();
  const [lastActiveTenant, setLastActiveTenant] = useState(activeTenantId);
  useEffect(() => {
    if (activeTenantId) {
      setLastActiveTenant(activeTenantId);
    }
  }, [activeTenantId]);
  return lastActiveTenant ? { lastActiveTenant, loading: false } : undefined;
};

export const lastActiveTenantLocalStorageKey = "mama-last-active-tenant";
const useLastActiveTenantFromLocalStorage = ():
  | LastActiveTenant
  | undefined => {
  const activeTenantId = useOptionalTenantId();
  const lastActiveTenant = useMemo(() => {
    if (activeTenantId) {
      try {
        localStorage.setItem(
          lastActiveTenantLocalStorageKey,
          JSON.stringify(activeTenantId),
        );
      } catch {
        /* empty */
      }
    }
    try {
      return assertIsValidTenantIdentifier(
        JSON.parse(localStorage.getItem(lastActiveTenantLocalStorageKey) ?? ""),
      );
    } catch {
      return undefined;
    }
  }, [activeTenantId]);
  return lastActiveTenant ? { lastActiveTenant, loading: false } : undefined;
};

export const lastActiveTenantSessionStorageKey = "mama-last-active-tenant";
const useLastActiveTenantFromSessionStorage = ():
  | LastActiveTenant
  | undefined => {
  const activeTenantId = useOptionalTenantId();
  const lastActiveTenant = useMemo(() => {
    if (activeTenantId) {
      try {
        sessionStorage.setItem(
          lastActiveTenantSessionStorageKey,
          JSON.stringify(activeTenantId),
        );
      } catch {
        /* empty */
      }
    }
    try {
      return assertIsValidTenantIdentifier(
        JSON.parse(
          sessionStorage.getItem(lastActiveTenantSessionStorageKey) ?? "",
        ),
      );
    } catch {
      return undefined;
    }
  }, [activeTenantId]);
  return lastActiveTenant ? { lastActiveTenant, loading: false } : undefined;
};

const useLastActiveTenantFromDb = (
  hasApiAccess: boolean,
): LastActiveTenant | undefined => {
  const activeTenantId = useOptionalTenantId();

  const { data: lastActiveTenant, isFetched: lastActiveTenantIsFetched } =
    useApiQuery(
      "backend",
      (api) => api.getLastActiveTenant(),
      LAST_ACTIVE_TENANT_KEY,
      {},
      { enabled: hasApiAccess },
    );
  const { mutate: setLastActiveTenant, isLoading: mutateIsLoading } =
    useApiMutation(
      "backend",
      (api) => (request: SetLastActiveTenantRequest) =>
        api.setLastActiveTenant(request),
      undefined,
      undefined,
      {
        onSuccess() {
          queryClient.invalidateQueries(LAST_ACTIVE_TENANT_KEY);
        },
      },
    );

  useEffect(() => {
    if (
      hasApiAccess &&
      lastActiveTenantIsFetched &&
      !mutateIsLoading &&
      activeTenantId &&
      lastActiveTenant &&
      !tenantIdEquals(activeTenantId, lastActiveTenant as TenantIdentifier)
    ) {
      setLastActiveTenant({ lastActiveTenant: activeTenantId });
    }
  }, [
    activeTenantId,
    hasApiAccess,
    lastActiveTenant,
    lastActiveTenantIsFetched,
    mutateIsLoading,
    setLastActiveTenant,
  ]);

  return hasApiAccess
    ? {
        lastActiveTenant: activeTenantId
          ? activeTenantId
          : (lastActiveTenant as TenantIdentifier | undefined),
        loading: !activeTenantId && !lastActiveTenantIsFetched,
      }
    : undefined;
};
