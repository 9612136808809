import { AudioLines, Delete, Mic, MicOff } from "lucide-react";
import { forwardRef, useEffect, useImperativeHandle } from "react";

import { useToasts } from "../app-shell/providers/toasts-provider/toasts-provider";
import { Text } from "../text";
import { cn } from "~/util/cn";
import { useAudioRecorder } from "~/util/use-record-audio";

type ButtonRecordingProps = {
  audio: string;
  onAudioCreated: (base64Audio: string) => void;
  onAudioRemoved: () => void;
  className?: string;
};

export type ButtonRecordingRef = {
  removeRecording: () => void;
};

export const ButtonRecording = forwardRef<
  ButtonRecordingRef,
  ButtonRecordingProps
>(({ audio, onAudioCreated, onAudioRemoved, className }, ref) => {
  useImperativeHandle(ref, () => ({
    removeRecording,
  }));

  const {
    toggleRecordAudio,
    removeRecording,
    base64Audio,
    isRecording,
    recordingDuration,
  } = useAudioRecorder();

  const toasts = useToasts();
  useEffect(() => {
    if (base64Audio && !isRecording) {
      if (base64Audio === "data:") {
        removeRecording();
        toasts.addToast({
          component: <Text tx="chat.errors.audioRecordingTooShort" />,
          variant: "error",
        });
        return;
      }

      onAudioCreated(base64Audio);
    }
  }, [base64Audio, isRecording, onAudioCreated, removeRecording, toasts]);

  return audio ? (
    <button
      type="button"
      className={cn("group btn-ghost btn-square btn-sm btn", className)}
      onClick={() => {
        removeRecording();
        onAudioRemoved();
      }}
    >
      <Delete width={18} />
    </button>
  ) : (
    <button
      type="button"
      className={cn(
        "group btn-primary btn-outline btn-sm btn",
        !isRecording && "btn-square",
        className,
      )}
      onClick={toggleRecordAudio}
    >
      {isRecording ? (
        <>
          <div className="relative h-6 w-6">
            <MicOff
              width={18}
              className="absolute inset-0 opacity-0 transition-opacity duration-500 ease-in-out group-focus-within:opacity-100 group-hover:opacity-100"
            />
            <Mic
              width={18}
              className="absolute inset-0 opacity-100 transition-opacity duration-500 ease-in-out group-focus-within:opacity-0 group-hover:opacity-0"
            />
          </div>
          <span className="w-9">{recordingDuration}</span>
        </>
      ) : (
        <AudioLines width={18} />
      )}
    </button>
  );
});

ButtonRecording.displayName = "ButtonRecording";
