import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeploymentEnvironment } from "shared/config/deployment-environments";
import { Disease } from "shared/model/diseases";
import {
  ConversationWithMessages,
  WebSocketRequest,
  WebSocketResponseEventData,
} from "shared/model/websocket/schema";

import { StartAnswerConversationRequest } from "../../../../api/generated/multiagent";
import { useWebSocket } from "../../../../api/use-web-socket";
import { queryClient } from "../../../../query-client";
import {
  ANSWER_COUNT_KEY,
  ANSWER_KEY,
  ANSWERS_KEY,
} from "../../../../types/query-keys";
import { appEnv, isNotLocalhost } from "../../../../util/env-utils";
import { useGetExtendedTheme } from "../../../../util/use-get-theme";
import { LoadingScreen } from "../../../loading-screen";
import { Conversation } from "../ask/chat/conversation";
import { mamaAnswerChatDrawerComponents } from "../ask/chat/lookup";
import { useApiMutation, useApiQuery } from "~/api/use-api";
import { useTenantId } from "~/util/use-active-tenant-id";

type InitializationState =
  | "pending"
  | "fetch_conversation"
  | "create_conversation"
  | "done";

export const MamaAnswer: React.FC = () => {
  const { colors } = useGetExtendedTheme();

  const [conversationId, setConversationId] = useState<string>("");
  const [conversation, setConversation] = useState<
    ConversationWithMessages | undefined
  >(undefined);
  const [initializationState, setInitializationState] =
    useState<InitializationState>("pending");

  const { disease } = useTenantId();
  const {
    i18n: { language },
  } = useTranslation();

  useApiQuery(
    "multiagent",
    (api) => api.getAnswerConversations({ mamaDisease: disease }),
    ANSWERS_KEY(disease),
    undefined,
    {
      refetchOnWindowFocus: false,
      onSuccess(conversationRecords) {
        if (conversationRecords.length === 0) {
          setInitializationState("create_conversation");
          return;
        } else {
          setConversationId(conversationRecords[0].id);
          setInitializationState("fetch_conversation");
        }
      },
    },
  );

  useApiQuery(
    "multiagent",
    (api) =>
      api.getAnswerConversationWithId({
        conversationId,
      }),
    ANSWER_KEY(disease, conversationId),
    {},
    {
      enabled: !!conversationId && initializationState === "fetch_conversation",
      refetchOnWindowFocus: false,
      onSuccess(conversationsWithMessages) {
        setInitializationState("done");
        setConversation(conversationsWithMessages as ConversationWithMessages);
      },
    },
  );

  const { mutate: createAnswerConversation } = useApiMutation(
    "multiagent",
    (api) => (request: StartAnswerConversationRequest) =>
      api.startAnswerConversation(request),
    undefined,
    undefined,
    {
      onSuccess: (_, { mamaDisease }) => {
        queryClient.invalidateQueries(ANSWERS_KEY(mamaDisease as Disease));
      },
    },
  );

  useEffect(() => {
    if (initializationState === "create_conversation") {
      createAnswerConversation({
        mamaDisease: disease,
        mamaLanguage: language,
      });
    }
  }, [disease, initializationState, language, createAnswerConversation]);

  const useAgnosticWebSocket = (params: {
    key?: string;
    query?: Record<string, string>;
    onResponse?: (
      event?: MessageEvent<WebSocketResponseEventData>,
    ) => Promise<void> | void;
  }) => {
    return useWebSocket<WebSocketRequest, WebSocketResponseEventData>({
      key: params.key,
      url: getWebSocketUrl(),
      query: {
        ...params.query,
        disease: disease,
      },
      onResponse: (response) => {
        queryClient.invalidateQueries(ANSWER_COUNT_KEY(disease));
        params.onResponse?.(response);
      },
    });
  };

  return !conversation ? (
    <LoadingScreen message={{ tx: "chat.loadingInitialChat" }} />
  ) : (
    <Conversation
      chatDrawerComponents={mamaAnswerChatDrawerComponents}
      conversation={conversation}
      useAgnosticWebSocket={useAgnosticWebSocket}
      shouldShowChatCompletion={false}
      userChatColors={
        colors && {
          bubbleColor: colors["bg-blue-900"],
          textColor: colors["mama-white"],
        }
      }
    />
  );
};

const getWebSocketUrl = () => {
  return !isNotLocalhost
    ? "ws://localhost:8000/answer/ws"
    : appEnv === DeploymentEnvironment.DEV
    ? "wss://ws.dev.mamahealth.com/answer/ws"
    : "wss://ws.app.mamahealth.com/answer/ws";
};
