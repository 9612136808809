import { HelpCircle } from "lucide-react";

import { BottomDrawerResponsive } from "~/components/bottom-drawer/responsive";
import { ChatTutorial } from "~/components/chat/chat-tutorial";
import { Text } from "~/components/text";

export const ButtonTutorial: React.FC = () => {
  return (
    <BottomDrawerResponsive
      button={({ open }) => (
        <button
          type="button"
          onClick={open}
          className="btn-ghost btn-block btn justify-start"
        >
          <HelpCircle width={16} />
          <Text tx="profile.tutorial" />
        </button>
      )}
    >
      <ChatTutorial />
    </BottomDrawerResponsive>
  );
};
